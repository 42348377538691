import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// APP
import { setGlobalLoading } from 'containers/App/actions';

// SHARED
import { setFooterBackground } from 'shared/footer/actions';

// COMPONENTS
import BackButton from 'components/Button/Back';
import Footer from 'components/Footer';
import Header from 'components/Header';
import PageContainer from 'components/PageContainer';
import Section from 'components/Section';

// CONSTANTS
import { Background } from 'constants/Background';
import { Position } from 'constants/Position';

// STYLES
import styled from 'styles/styled-components';

// STYLED COMPONENTS
const Container = styled.div`
  padding: 100px 32px 64px;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Heading = styled.h1`
  text-align: center;
  margin: 16px 0 64px 0;
`;
const Subheader = styled.h3``;
const BoldHeading = styled.h6`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
`;
const Paragraph = styled.p`
  margin-top: 8px;
  margin-bottom: 20px;
`;

const PrivacyPolicy: React.FC = () => {
  const dispatch = useDispatch();

  /**
   * Set footer background
   * Set global loading
   */
  useEffect((): void => {
    dispatch(setFooterBackground(Background.LIGHT));
    dispatch(setGlobalLoading(false));
  }, [
    dispatch,
  ]);

  return (
    <PageContainer>
      <Header
        iconPosition={Position.CENTER}
        left={(
          <BackButton />
        )}
      />
      <Section flex full background={Background.DARK}>
        <Container>
          <Heading>Privacy Policy</Heading>
          <Paragraph>
            This Privacy Policy (this “Policy”) is one way of sustaining your trust in our products, information and services. This Policy describes how we collect, protect, and use information about you that you submit to us directly or during your visit on our website. At all times, we reserve the right to disclose any information, including personally identifiable information, to comply with any applicable law, regulation, legal process or governmental request; to protect our rights or property, or during an emergency when safety is at risk, or for fraud protection and risk reduction purposes.
          </Paragraph>

          <Subheader>CALIFORNIA PRIVACY RIGHTS NOTICE</Subheader>
          <Paragraph>
            This California Privacy Rights Notice supplements the information contained in this Policy and applies to California residents (“consumers” or “you”). If you are a California resident, then you have certain additional rights under the California Consumer Privacy Act (“CCPA”) regarding Personal Information as defined in the CCPA.
          </Paragraph>

          <Subheader>CALIFORNIA PRIVACY RIGHTS</Subheader>

          <BoldHeading>Information Collection and Use</BoldHeading>
          <Paragraph>
            We collect information about you to help us serve your needs, to provide you with quality products and services, and to fulfill legal and regulatory requirements. We consider all information about you in our possession to be personal information. Typically, we collect this information through communications you may have with our representatives and over this website. This information may include your name, phone number, email address, and employer.
          </Paragraph>
          <Paragraph>
            We do not sell or rent personal information. We may share information with certain employees, and with companies directly providing services on our behalf (as our vendor or agent) in order to service your needs. Our policy is to require all such employees and companies providing services on our behalf, to keep collected personal information confidential. This Policy applies to potential customers, as well as current and former customers.
          </Paragraph>
          <Paragraph>
            We may also collect, store or accumulate certain non-personally identifiable information concerning your use of this website, such as information regarding which of our pages are most popular. Information gathered may be used in aggregate form for internal business purposes, such as generating statistics and developing marketing plans. We may share or transfer such aggregate, non-personally identifiable information with or to our affiliates (including without limitation Loanpal, LLC).
          </Paragraph>
          <Paragraph>
            If applicable, when you fill-out a form on our website, we capture your IP-address. This enables us to track and prevent fraudulent attacks against our website as a security precaution. We do not use your IP-address to identify or contact you.
          </Paragraph>

          <BoldHeading>Special Note to Parents</BoldHeading>
          <Paragraph>
            This website is intended for adults. We do not knowingly collect personal information from children under the age of 13. However, if the parent or guardian of a child under 13 believes that the child has provided us with personally identifiable information, the parent or guardian of that child should contact us immediately at gencounsel@loanpal.com if they want this information deleted from our files, so that it is not in retrievable form. If we otherwise obtain knowledge that we have personally identifiable information about a child under 13 in retrievable form in our files, we will delete the information from our existing files so that it is not retrievable.
          </Paragraph>

          <BoldHeading>Ensuring Your Security</BoldHeading>
          <Paragraph>
            We have adopted policies and procedures designed to protect your personal information from unauthorized use or disclosure.
          </Paragraph>
          <Paragraph>
            We have implemented physical, technical, and procedural safeguards to maintain confidentiality and integrity of the personal information in our possession, and to guard against unauthorized access. These include among other things, procedures for controlling access to customer files, building security programs, and information technology security measures such as the use of passwords, firewall, plus virus and use detection software. We continue to access new technology as it becomes available, and to upgrade our physical and technical security systems as appropriate.
            Our policy is to permit employees to access your personal information only if they have a business purpose for using such information, such as administering, providing or developing our products, information or services. This Policy governs the conduct of all of our employees and third-party vendors, to safeguard personal information about consumers, and customers we serve or have served in the past.
          </Paragraph>

          <BoldHeading>Sharing Information With Companies That Provide Services For Us</BoldHeading>
          <Paragraph>
            We share personal information about you, as required or permitted by law, with affiliates and third-parties such as, service providers who assist us in the day-to-day operations of our company. Our policy is to require affiliates and third-party service providers to enter into confidentiality agreements with us, prohibiting them from using any personal information they obtain for any other purpose other than those for which they were retained or as required by law. We may also disclose information about you, when necessary or required, in legal and arbitration proceedings, and to government agencies.
          </Paragraph>

          <BoldHeading>Technology Utilized</BoldHeading>
          <Paragraph>
            There are two commonly used technologies used on this website, clear gifs and cookies. We use these to monitor the performance and effectiveness of the website. “Cookies” are small pieces of information stored by our browser on your computer’s hard drive. We do not use cookies to obtain any personally identifying information. Most web browsers automatically accept cookies, but you can usually change your browser settings to prevent this. Please be assured that accepting a cookie does not give us access to your computer or personal information under any circumstances. If you disable cookies, you may be unable to use some features of our site. If you experience any problems with our website, please click the link Report a Bug, and email us the issue encountered. Clear gifs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. Clear gifs are not tied to users’ personally identifiable information.
          </Paragraph>

          <BoldHeading>Notification of Changes</BoldHeading>
          <Paragraph>
            We may revise this Privacy Policy from time to time. If we decide to change our Privacy Policy, we will post the revised policy here. As we may make changes at any time without notifying you, we suggest that you periodically consult this Privacy Policy.
          </Paragraph>

          <BoldHeading>Your Consent</BoldHeading>
          <Paragraph>
            Your continued participation on this website indicates your acceptance of this Privacy Policy, and of the collection, use, disclosure, management, and storage of your personal information as described above. We may from time-to-time, transfer or merge any personal information collected offline to our online databases or store offline information in an electronic format. We may also combine personal information we collect online with information available from other sources.
          </Paragraph>

          <BoldHeading>Contacting Us</BoldHeading>
          <Paragraph>
            If you have any questions about this privacy policy, please contact us by mail at:
          </Paragraph>

          <Paragraph>
            NextIQ<br />
            8781 Sierra College Blvd<br />
            Roseville CA, 95661<br />
            Attention: General Counsel
          </Paragraph>

          <BoldHeading>Effective Date of this Policy:</BoldHeading>
          <Paragraph>
            This policy was last revised on 11/6/2020 and takes effect immediately, and is in effect until further revised.
          </Paragraph>
        </Container>
      </Section>
      <Footer />
    </PageContainer>
  );
};
export default PrivacyPolicy;
