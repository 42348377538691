import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// APP
import { setGlobalLoading } from 'containers/App/actions';

// SHARED
import { setFooterBackground } from 'shared/footer/actions';

// COMPONENTS
import BackButton from 'components/Button/Back';
import Footer from 'components/Footer';
import Header from 'components/Header';
import PageContainer from 'components/PageContainer';
import Section from 'components/Section';

// CONSTANTS
import { Background } from 'constants/Background';
import { Position } from 'constants/Position';

// STYLES
import styled from 'styles/styled-components';

// STYLED COMPONENTS
const Container = styled.div`
  padding: 100px 32px 64px;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Heading = styled.h1`
  text-align: center;
  margin: 16px 0 64px 0;
`;
const Subheader = styled.h3``;
const Paragraph = styled.p`
  margin-top: 8px;
  margin-bottom: 20px;
`;

const TermsAndConditions: React.FC = () => {
  const dispatch = useDispatch();

  /**
   * Set footer background
   * Set global loading
   */
  useEffect((): void => {
    dispatch(setFooterBackground(Background.LIGHT));
    dispatch(setGlobalLoading(false));
  }, [
    dispatch,
  ]);

  return (
    <PageContainer>
      <Header
        iconPosition={Position.CENTER}
        left={(
          <BackButton />
        )}
      />
      <Section flex full background={Background.DARK}>
        <Container>
          <Heading>Terms of Use</Heading>

          <Subheader>TERMS OF USE</Subheader>
          <Paragraph>
            Please read this Agreement carefully before accessing or using this web site (Site). By accessing or using the Site, you agree to be bound by this Agreement.  In addition, if you are accessing or using this Site through your employer, you agree to be bound by all the limitations, terms and conditions of your employer’s agreement with NextIQ regarding access to this Site. NextIQ provides the information and services on this site to you, the user, conditioned on your acceptance without modification of the terms, conditions and notices contained herein and your employer’s agreement. Your use of this site constitutes your agreement to all such terms, conditions, and notices. 
          </Paragraph>
          
          <Paragraph>
            The information and services offered on this site are provided with the understanding that NextIQ is not engaged in rendering legal or other professional services or advice. 
          </Paragraph>
          
          <Paragraph>
            Your use of the Site is subject to the additional disclaimers and caveats that may appear throughout the Site.
          </Paragraph>
          
          <Paragraph>
            NextIQ and its agents assume no responsibility for any consequence relating directly or indirectly to any action or inaction that you take based on the information, services or other material on this Site. While NextIQ and its suppliers, strive to keep the information on this Site accurate, complete and up-to-date, NextIQ and its suppliers cannot guarantee, and will not be responsible for any damage or loss related to, the accuracy, completeness or timeliness of the information.
          </Paragraph>
          
          <Subheader>PROHIBITED USES</Subheader>
          <Paragraph>
            You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, frame in another web page, use on any other web site, transfer or sell any information, software, lists of users, databases, videos or other lists, products or services obtained from this Site. The foregoing prohibition expressly includes, but is not limited to, the practices of “screen scraping” or “database scraping” to obtain lists of users or other information. If and when requested by NextIQ, you agree to provide true, accurate and complete user information and to refrain from impersonating or falsely representing your affiliation with any person or entity. Except with the written permission of NextIQ, you agree to refrain from accessing or attempting to access password protected, secure or non-public areas of this Site. Unauthorized individuals attempting to access prohibited areas of this Site may be subject to prosecution.
          </Paragraph>
          
          <Paragraph>
            As a condition of your use of this Site, you warrant to NextIQ, that you will not use this Site for any purpose that is unlawful or prohibited by these terms, conditions, and notices. If you violate any of these terms, your permission to use the Site automatically terminates.
          </Paragraph>
          
          <Subheader>LIABILITY DISCLAIMER</Subheader>
          <Paragraph>
            You use this Site at your own risk. If your use of this Site or the materials therein results in the need for servicing or replacing property, material, equipment or data, NextIQ, is not responsible for those costs.
          </Paragraph>
          
          <Paragraph>
            The information, software, products, and services published on this site may include inaccuracies or typographical errors. Changes are periodically added to the information herein. NextIQ may without prior notice make improvements and/or changes in this site including but not limited to the information, services, products or other material at any time. All information, products, and services are provided “as is” without warranty of any kind. In no event shall NextIQ be liable for any direct, indirect, special or consequential damages arising out of or in any way connected with the use of this site, or for any information, products, material and/or service obtained through this site, whether, based on contract, tort, strict liability or otherwise.
          </Paragraph>

          <Paragraph>
            A link to another website does not constitute an endorsement of that site (nor of any product, service or other material offered on that site) by NextIQ.
          </Paragraph>

          <Subheader>LIMITATION OF LIABILITY</Subheader>
          <Paragraph>
            IN NO EVENT SHALL NEXTIQ OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR SUPPLIERS BE LIABLE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR INABILITY TO USE, THIS WEBSITE OR THE CONTENT OR SERVICES CONTAINED ON, OR ACCESSED THROUGH, THIS WEBSITE, EVEN IF NEXTIQ HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Paragraph>

          <Paragraph>
            Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so some of the above may not apply to you.
          </Paragraph>

          <Subheader>COPYRIGHTS; LIMITED LICENSE</Subheader>
          <Paragraph>
            All Site materials, including, without limitation, the NextIQ logo, and all designs, text, graphics, pictures, information, data, software, videos, sound files, messages, and other files and materials on the Site, and the selection and arrangement thereof (collectively, ‘Content’) are the copyright and proprietary property of NextIQ or its affiliates, users or licensors. You are granted a limited, non-sublicensable license to access and use the Site and may electronically copy and print to hard copy portions of this Site for the sole purpose of using materials it contains for educational and informational use only, provided that you keep all copyright or other proprietary notices intact. 
          </Paragraph>

          <Paragraph>
            Any other use of Content, including any resale, reproduction for purposes other than described above, modification, distribution, republication, display, or performance, without the prior written permission of NextIQ is strictly prohibited. This license is subject to these Terms of Use and does not include the right to (a) modify or otherwise make any derivative uses of the Site and the Content, or any portion thereof or to use any data mining, robots or similar data gathering or extraction methods (b) use the Site or the Content other than for its intended purpose. Any use of the Site or Content other than as specifically authorized herein, without the prior written permission of NextIQ, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws. This license is revocable at any time.
          </Paragraph>

          <Subheader>USER ACCOUNTS AND SECURITY</Subheader>
          <Paragraph>
            To obtain access to certain services on the Site, you may be given an opportunity to register with us. As part of any such registration process, you will select a user name and a password. You agree that the information you supply during that registration process will be accurate and complete. You also agree not to (i) select, register, or attempt to register, or use a user name of another person with the intention of impersonating that person; (ii) use a user name of anyone else without authorization; (iii) use a user name in violation of the intellectual property rights of any person; or (iv) use a user name that NextIQ considers to be offensive. NextIQ reserves the right to reject or terminate any user name or password that, in its judgment, it deems offensive. You will be responsible for preserving the confidentiality of your password and will notify NextIQ of any known or suspected unauthorized use of your account. Further, you agree that you are responsible for all statements made and acts or omissions that occur on your account while your password is being used. If you believe someone has used your password or account without your authorization, you must notify NextIQ immediately. 
          </Paragraph>

          <Subheader>CHANGES TO AGREEMENT</Subheader>
          <Paragraph>
            NextIQ may modify this Agreement at any time, and such modifications shall be effective immediately upon posting of the modified Agreement. Accordingly, you agree to review the Agreement periodically, and your continued access or use of this Site shall be deemed your acceptance of the modified Agreement.
          </Paragraph>
            
          <Subheader>MISCELLANEOUS</Subheader>
          <Paragraph>
            This website is applicable to and should only be used by persons 18 years of age or older located within the United States of America. Access and use of this website by persons under the age of 18 or from locations other than the United States is prohibited.
          </Paragraph>
        </Container>
      </Section>
      <Footer />
    </PageContainer>
  );
};
export default TermsAndConditions;
