/**
 * The Quiz state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectApplication = (state: ApplicationRootState) => {
  return state.jobApplication || initialState;
};

const selectJobApplication = () =>
  createSelector(selectApplication, substate => substate.application);

const selectLoading = () =>
  createSelector(selectApplication, substate => substate.loading);

const selectSuccess = () =>
  createSelector(selectApplication, substate => substate.success);

const selectError = () =>
  createSelector(selectApplication, substate => substate.error);

export {
  selectJobApplication,
  selectLoading,
  selectSuccess,
  selectError
};