import {
  API, graphqlOperation 
} from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

// API
import {
  createApplication as create,
  updateApplication as update
} from 'graphql/mutations';
import {
  getApplication as get,
  listApplications as list
} from 'graphql/queries';

// MODELS
import {
  CreateApplicationInput, UpdateApplicationInput 
} from 'API';

/**
 * 
 * @param id
 * @param name
 */
export const updateApplication = (id: string, name: string, resume: string) => {
  const input: UpdateApplicationInput = {
    id: id,
    name: name,
    resume: resume,
  };
  return API.graphql({
    query: update,
    variables: input,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  });
};

/**
 * 
 * @param id 
 */
export const createApplication = (application: CreateApplicationInput) => 
  API.graphql(graphqlOperation(create, {
    input: application, 
  }));


/**
 * 
 * @param id 
 */
export const getApplication = (id: string) => 
  API.graphql(graphqlOperation(get, {
    id, 
  }));

/**
 * 
 */
export const listApplications = () =>
  API.graphql(graphqlOperation(list));
