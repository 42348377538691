import { action } from 'typesafe-actions';
import ActionTypes from './constants';

// MODELS
import Error from 'models/error';

export const setAppError = (error: Error) =>
  action(ActionTypes.SET_APP_ERROR, error);

export const setGlobalLoading = (loading: boolean) =>
  action(ActionTypes.SET_GLOBAL_LOADING, loading);

export const contactUs = (content: {
  subject: string,
  body: string,
}) => action(ActionTypes.CONTACT_US, content);

export const contactUsSuccess = () =>
  action(ActionTypes.CONTACT_US_SUCCESS);

export const contactUsFailure = (error: Error) =>
  action(ActionTypes.CONTACT_US_FAILURE, error);
