import React from 'react';

// STYLES
import styled, { css } from 'styles/styled-components';
import theme from 'styles/light-theme';
import media from 'styles/breakpoints';

import { Size } from 'constants/Size';
import { Align } from 'constants/Align';
import { Weight } from 'constants/Weight';

interface AttributeProps {
  size?: Size
  align?: Align
  weight?: Weight
  maxWidth?: number
  textAlign?: Align
}

interface ColorProps {
  primary?: string
  secondary?: string
}

interface TitleProps extends AttributeProps {
  title: string
  subtitle?: string
  className?: string
}

interface SpacingProps {
  marginTop?: string
  marginBottom?: string
  margin?: string
}

const alignment = (align: Align = Align.LEFT) => {
  switch (align) {
    case Align.CENTER:
      return 'center';
    case Align.RIGHT:
      return 'flex-end';
    case Align.LEFT:
    default:
      return 'flex-start';
  }
};

const sizing = (size: Size = Size.LARGE) => {
  switch (size) {
    case Size.SMALL:
      return theme.fonts.sizes.primary;
    case Size.MEDIUM:
      return theme.fonts.sizes.large;
    case Size.LARGE:
      return theme.fonts.sizes.xlarge;
    case Size.XLARGE:
      return theme.fonts.sizes.xxlarge;
    default:
      return theme.fonts.sizes.xlarge;
  }
};

const weights = (weight: Weight = Weight.MEDIUM) => {
  switch (weight) {
    case Weight.THIN:
      return theme.fonts.weights.thin;
    case Weight.BOOK:
      return theme.fonts.weights.book;
    case Weight.BOLD:
      return theme.fonts.weights.bold;
    case Weight.MEDIUM:
    default:
      return theme.fonts.weights.primary;
  }
};

const Container = styled.div<AttributeProps & SpacingProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => alignment(align)};
  z-index: 1;
  ${props => {
    let styles = '';
    if (props.maxWidth) {
      styles += `max-width: ${props.maxWidth}px;`;
    }
    if (props.marginTop) {
      styles += `margin-top: ${props.marginTop};`;
    }
    if (props.marginBottom) {
      styles += `margin-bottom: ${props.marginBottom};`;
    }
    if (props.margin) {
      styles += `margin: ${props.margin}`;
    }
    return styles;
  }}
`;
const Title = styled.div<AttributeProps & ColorProps>`
  color: ${props => props.primary || props.theme.textColors.primary};
  font-size: ${({ size }) => sizing(size)};
  font-weight: ${({ weight }) => weights(weight)};
  margin-bottom: 4px;
  text-align: ${({ textAlign }) => alignment(textAlign)};

  ${media.laptop} {
    ${props => {
    if (props.size === Size.LARGE || props.size === Size.XLARGE) {
      return css`font-size: 24px;`;
    } else if (props.size === Size.MEDIUM) {
      return css`font-size: 20px;`;
    } else if (props.size === Size.SMALL) {
      return css`font-size: 18px;`;
    }
  }}
  }

  ${media.tablet} {
    ${props => {
    if (props.size === Size.LARGE || props.size === Size.XLARGE) {
      return css`font-size: 22px;`;
    } else if (props.size === Size.MEDIUM) {
      return css`font-size: 18px;`;
    } else if (props.size === Size.SMALL) {
      return css`font-size: 16px;`;
    }
  }}
  }

  ${media.mobile} {
    ${props => {
    if (props.size === Size.LARGE || props.size === Size.XLARGE) {
      return css`font-size: 20px;`;
    } else if (props.size === Size.MEDIUM) {
      return css`font-size: 16px;`;
    } else if (props.size === Size.SMALL) {
      return css`font-size: 14px;`;
    }
  }}
  }
`;
const Subtitle = styled.div<ColorProps & AttributeProps>`
  color: ${props =>  props.secondary || props.theme.textColors.secondary};
  font-size: ${props => props.theme.fonts.sizes.small};
  font-weight: ${props => props.theme.fonts.weights.book};
  text-align: ${props => alignment(props.textAlign)};
`;

type Props = TitleProps & ColorProps & SpacingProps

const UniversalTitle: React.FC<Props> = ({
  size = Size.LARGE,
  align = Align.LEFT,
  weight = Weight.MEDIUM,
  textAlign = Align.LEFT,
  className,
  title,
  subtitle,
  primary,
  secondary,
  maxWidth,
  marginTop,
  marginBottom,
  margin,
}: Props) => {
  const containerProps = {
    align,
    maxWidth, 
  };
  const titleProps = {
    size,
    weight,
    primary,
    textAlign, 
  };
  const subtitleProps = {
    secondary,
    textAlign, 
  };
  const spacingProps = {
    marginTop,
    marginBottom,
    margin, 
  };
  return (
    <Container className={className} {...containerProps} {...spacingProps}>
      <Title {...titleProps}>{title}</Title>
      { subtitle && (
        <Subtitle {...subtitleProps}>{subtitle}</Subtitle>
      )}
    </Container>
  );
};

export default UniversalTitle;