import ActionTypes from './constants';
import {
  ContainerState, ContainerActions 
} from './types';

export const initialState: ContainerState = {
  loading: false,
  error: false,
  assets: [],
  episodes: [],
  paging: {
    total: 0,
    page: 1,
    next: null,
    previous: null,
    first: null,
    last: null,
  },
};

function vimeoReducer(
  state: ContainerState = initialState,
  action: ContainerActions
): ContainerState {
  switch (action.type) {

    case ActionTypes.LOAD_VIMEO_ASSETS:
    case ActionTypes.LOAD_VIMEO_EPISODES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ActionTypes.LOAD_VIMEO_ASSETS_FAILURE:
    case ActionTypes.LOAD_VIMEO_EPISODES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.LOAD_VIMEO_EPISODES_SUCCESS:
      return {
        ...state,
        loading: false,
        paging: action.payload.paging,
      };

    case ActionTypes.LOAD_VIMEO_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        assets: action.payload,
      };

    default:
      return state;
  }
}

export default vimeoReducer;