import React, {
  useEffect, useState 
} from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  OptionTypeBase, Props as SelectProps 
} from 'react-select';

import FormGroup from './FormGroup';
import FieldLabel from './FieldLabel';
import FieldError from './FieldError';

import lightTheme from 'styles/light-theme';

interface Props extends SelectProps<OptionTypeBase> {
  id: string
  name: string
  placeholder: string
  multi: boolean
  noOptionsMsg: string
  label?: string
  error?: string
}

const CreateableSelect: React.FC<Props> = ({
  id,
  name: _name,
  defaultValue: _defaultValue,
  options: _options,
  loading: _loading,
  placeholder: _placeholder,
  onChange: _onChange,
  title: _title,
  label: _label,
  error: _error,
  multi: _multi,
  noOptionsMsg: _noOptionsMsg = 'No options',
}: Props) => {
  const [ 
    styles,
    setStyles, 
  ] = useState<Record<string, unknown>>({
  });
  const { colors } = lightTheme;
  const background = colors.white;
  const borderRadius = lightTheme.radii.primary;
  const outline = 'none';
  const borderNone = 'none';
  const cursor = 'pointer';

  useEffect(() => {
    const customStyles = {
      container: provided => ({
        ...provided,
        outline: 'none',
      }),
      control: provided => {
        const flex = '1';
        const border = _error ? `1px solid ${colors.red}` : borderNone;
        return {
          ...provided,
          flex,
          background,
          border,
          borderColor: 'transparent',
          borderRadius,
          boxShadow: 'none',
          outline,
          cursor,
          '&:hover': {
            borderColor: 'transparent',
          },
        };
      },
      placeholder: (provided) => ({
        ...provided,
        outline,
        color: colors.black,
      }),
      input: provided => ({
        ...provided,
        color: colors.black,
        border: borderNone,
      }),
      menu: provided => {
        return {
          ...provided,
          background,
          border: borderNone,
          borderRadius,
          outline,
        };
      },
      menuList: provided => ({
        ...provided,
        border: borderNone,
      }),
      menuPortal: provided => ({
        ...provided,
        zIndex: 9999,
        border: borderNone,
      }),
      noOptionsMessage: provided => ({
        ...provided,
        color: colors.black,
        textAlign: 'left',
      }),
      option: provided => {
        return {
          ...provided,
          background: 'transparent',
          color: colors.black,
          minHeight: '36px',
          cursor,
        };
      },
      multiValue: provided => ({
        ...provided,
        borderRadius: '4px',
        cursor: 'default',
        backgroundColor: colors.black,
      }),
      multiValueLabel: provided => ({
        ...provided,
        color: colors.white,
      }),
      multiValueRemove: provided => ({
        ...provided,
        color: lightTheme.colors.red,
        borderRadius: '0 4px 4px 0',
        cursor,
        '&:hover': {
          color: colors.black,
          backgroundColor: colors.red,
        },
      }),
    };
    setStyles(customStyles);
  }, [
    _error,
    background,
    borderRadius,
    colors,
  ]);

  return (
    <FormGroup title={_title} aria-label={`multi creatable select field ${_name}`}>
      { _label && (
        <FieldLabel label={_label} />
      )}
      <CreatableSelect
        id={id}
        defaultValue={_defaultValue}
        options={_options}
        isLoading={_loading}
        onChange={_onChange}
        placeholder={_placeholder}
        isMulti={_multi}
        isClearable
        isSearchable
        styles={styles}
        menuPortalTarget={document.body}
        menuPlacement={'auto'}
        noOptionsMessage={() => `${_noOptionsMsg}`}
        closeMenuOnSelect={false}
      />
      { _error && (
        <FieldError name={_name} />
      )}
    </FormGroup>
  );
};
export default CreateableSelect;