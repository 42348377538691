import React from 'react';
import { createStructuredSelector } from 'reselect';
import {
  useDispatch, useSelector 
} from 'react-redux';
import ReactModal from 'react-modal';

// STYLES
import styled, { ThemeProvider } from 'styles/styled-components';
import lightTheme from 'styles/light-theme';
import {
  max, breaks 
} from 'styles/breakpoints'; 
import './Modal.css';

// COMPONENTS
import Button from 'components/Button';
import Img from 'components/Img';
import LoadingText from 'components/Loading/Ellipsis';

// SHARED
import { RootState } from 'shared/modal/types';
import { closeModal } from 'shared/modal/actions';
import { makeSelectSelectedModal } from 'shared/modal/selectors';

// ASSETS
import X from 'assets/svgs/close-x-black.svg';

// CONSTANTS
import { Align } from 'constants/Align';
import { LoadingState } from 'constants/LoadingState';
import { Size } from 'constants/Size';

// STYLED COMPONENTS
const StyledReactModal = styled(ReactModal)<SizeProps>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radii.large};
  min-width: 450px;
  max-width: 80vw;
  max-height: 95vh;
  width: ${props => props.width ? (props.width + 'px') : 'auto'};
  height: ${props => props.height ? (props.height + 'px') : 'auto'};
  z-index: 1001;
  outline: none;

  ${max(breaks.mobile.max)} {
    min-width: inherit;
    max-width: inherit;
    max-height: inherit;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;
const ModalHeader = styled.div<ButtonProps>`
  position: relative;
  min-height: 72px;
  font-size: 18px;
  padding: 22px;
  ${props => {
    if (props.showCloseX) {
      return 'padding-right: 64px;';
    }
  }}
  display: flex;
  // justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;
const Title = styled.div<AlignProps>`
  flex: 1;

  ${props => {
    if (props.titleAlign === Align.CENTER) {
      return 'text-align: center;';
    }
    return '';
  }}
`;
const XButton = styled.button`
  position: absolute;
  right: 22px;
  top: 22px;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  // transform: scale(0.9);
  transition: transform 200ms ease;
  will-change: transform;

  img {
    height: 20px;
    // margin-bottom: 0;
  }

  &:hover {
    transform: rotate(90deg);
    // transform: rotate(90deg) scale(1.1);
  }
`;
const ModalContent = styled.div`
  // min-height: 420px;
  max-height: calc(100% - 72px - 72px);
  padding: 0 1.5rem;
  overflow: auto;
  flex: 1;
`;
const ModalFooter = styled.div`
  min-height: 72px;
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:not(:last-of-type) {
    margin-right: 22px;
  }
`;
const CancelButton = styled(Button)``;
const SaveButton = styled(Button)``;

interface ModalSelection {
  selectedModalId: string
}
const stateSelector = createStructuredSelector<RootState, ModalSelection>({
  selectedModalId: makeSelectSelectedModal(),
});

interface ModalProps {
  id: string
  title: string
  createText?: string
  updateText?: string
  deleteText?: string
  archiveText?: string
  loadingState?: LoadingState
  onClose?: (e: unknown) => void
  onSave?: (e: unknown) => void
  children?: React.ReactNode
  closeOnOverlayClick?: boolean
  closeOnEsc?: boolean
}

interface ButtonProps {
  saveText?: string
  cancelText?: string
  showSave?: boolean
  showCancel?: boolean
  showCloseX?: boolean
}

interface SizeProps {
  width?: number
  height?: number
  buttonSize?: Size
}

interface AlignProps {
  titleAlign?: Align
}

type Props = ModalProps & ButtonProps & SizeProps & AlignProps

const Modal: React.FC<Props> = ({
  id,
  title,
  createText = 'Uploading',
  updateText = 'Updating',
  deleteText = 'Removing',
  archiveText = 'Archiving',
  loadingState = LoadingState.IDLE,
  saveText = 'Ok',
  cancelText = 'Cancel',
  showSave = true,
  showCancel = true,
  showCloseX = true,
  onClose,
  onSave,
  children,
  width,
  height,
  buttonSize = Size.SMALL,
  closeOnOverlayClick = false,
  closeOnEsc = false,
  titleAlign = Align.LEFT,
}: Props) => {
  const { selectedModalId } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const handleClose = (e): void => {
    if (onClose) {
      onClose(e);
    } else {
      dispatch(closeModal());
    }
  };
  const handleSave = (e): void => {
    onSave && onSave(e);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <StyledReactModal
        isOpen={selectedModalId === id}
        contentLabel="add, view, and edit dynamic content"
        onRequestClose={onClose}
        overlayClassName="x-overlay"
        appElement={document.getElementById('root')}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
        shouldCloseOnEsc={closeOnEsc}
        // onAfterOpen
        width={width}
        height={height}
        buttonSize={buttonSize}
      >
        <ModalHeader showCloseX={showCloseX}>
          <Title titleAlign={titleAlign}>{title}</Title>
          { showCloseX && (
            <XButton onClick={handleClose} disabled={loadingState !== LoadingState.IDLE}>
              <Img src={X} alt="close button" />
            </XButton>
          )}
        </ModalHeader>
        <ModalContent>
          {children}
          { loadingState === LoadingState.CREATE && (
            <LoadingText align={Align.CENTER}>{createText}</LoadingText>
          )} { loadingState === LoadingState.UPDATE && (
            <LoadingText align={Align.CENTER}>{updateText}</LoadingText>
          )} { loadingState === LoadingState.DELETE && (
            <LoadingText align={Align.CENTER}>{deleteText}</LoadingText>
          )} { loadingState === LoadingState.ARCHIVE && (
            <LoadingText align={Align.CENTER}>{archiveText}</LoadingText>
          )}
        </ModalContent>
        <ModalFooter>
          { showCancel && 
            <CancelButton
              secondary
              onClick={handleClose} 
              disabled={loadingState !== LoadingState.IDLE}
              size={buttonSize}
            >
              {cancelText}
            </CancelButton>
          } { showSave &&
            <SaveButton
              onClick={handleSave}
              disabled={loadingState !== LoadingState.IDLE}
              size={buttonSize}
            >
              {saveText}
            </SaveButton>
          }
        </ModalFooter>
      </StyledReactModal>
    </ThemeProvider>
  );
};
export default Modal;