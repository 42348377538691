/* eslint-disable no-irregular-whitespace */
// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more : Extra large screens, TV
export const breaks = {
  mobile: {
    max: 480,
  },
  tablet: {
    min: 481,
    max: 768,
  },
  laptop: {
    min: 769,
    max: 1024,
  },
  desktop: {
    min: 1025,
    max: 1200,
  },
  large: {
    min: 1201,
  },
};

export const min = (min: number) =>
  `@media only screen and (min-width: ${min}px)`;

export const max = (max: number) =>
  `@media only screen and (max-width: ${max}px)`;

export const minMax = (min: number, max: number) =>
  `@media only screen and (min-width: ${min}px) and (max-width: ${max}px)`;

const media = {
  mobile: max(breaks.mobile.max),
  tablet: minMax(breaks.tablet.min, breaks.tablet.max),
  laptop: minMax(breaks.laptop.min, breaks.laptop.max),
  desktop: minMax(breaks.desktop.min, breaks.desktop.max),
  large: min(breaks.large.min),
};

export default media;




/*
 * Media queries utility
 */

// import {
//   css,
//   DefaultTheme,
//   CSSObject,
//   InterpolationFunction,
//   ThemedStyledProps,
//   Interpolation,
//   FlattenInterpolation,
// } from 'styled-components/macro';

/*
 * Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32914
 */

// Update your breakpoints if you want
// export const sizes = {
//   mobile: 480,
//   tablet: 768,
//   laptop: 1024,
//   desktop: 1200,
//   tv: 1201,
// }
// export const sizes = {
//   small: 600,
//   medium: 1024,
//   large: 1440,
//   xlarge: 1920,
// }

// Iterate through the sizes and create a media template
// const media =
//   (Object.keys(sizes) as Array<keyof typeof sizes>)
//     .reduce(
//       (acc, label) => {
//         acc[label] = (first: any, ...interpolations: any[]) => css`
//           @media (min-width: ${sizes[label]}px) {
//             ${css(first, ...interpolations)}
//           }
//         `

//         return acc;
//       },
//       {} as { [key in keyof typeof sizes]: MediaFunction },
//     )

// export default(media)

/*
 * @types/styled-component is not working properly as explained in the github issue referenced above.
 * We must overcome this with custom typings, however, this might not work in time as the styled-components update.
 * Be carefull and keep an eye on the issue and the possible improvements
 */
// type MediaFunction = <P extends object>(
//   first:
//     | TemplateStringsArray
//     | CSSObject
//     | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
//   ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
// ) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>

/* Example
const SomeDiv = styled.div`
  display: flex;
  ....
  ${media.medium`
    display: block
  `}
`;
*/