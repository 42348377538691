import React from 'react';
import { Field } from 'formik';
import ReactTooltip from 'react-tooltip';

// STYLES
import styled, { css } from 'styles/styled-components';

// COMPONENTS
import CircularLoading from 'components/CircularLoading';
import Img from 'components/Img';

// ASSETS
import Question from 'assets/svgs/question.svg';

// CONSTANTS
import { Size } from 'constants/Size';

// STYLED COMPONENTS
interface IconProps {
  icon?: any
  iconPosition?: {
    top?: string,
    right?: string,
    bottom?: string,
    left?: string,
  }
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
const InputLabel = styled.label`
  font-size: ${props => props.theme.fonts.sizes.small};
  font-weight: ${props => props.theme.fonts.weights.primary};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: ${props => props.theme.spacing.belowTitle};
`;
export const InputContainer = styled.div<IconProps>`
  position: relative;
  display: flex;
  align-items: center;
  ${props => {
    let content = '';
    if (props.icon) {
      content = `
      &::before {
        content: url(${props.icon});
        position: absolute;
        top: ${props.iconPosition?.top || 'unset'};
        right: ${props.iconPosition?.right || 'unset'};
        bottom: ${props.iconPosition?.bottom || 'unset'};
        left: ${props.iconPosition?.left || 'unset'};
        z-index: 1;
      }`;
    }
    return content;
  }}
`;
export const InputField = styled.input<IconProps>`
  flex: 1;
  height: ${props => props.theme.elements.textInput.height};
  padding: calc((${props => props.theme.spacing.elementContent}) / 2) 0;
  padding-left: ${props => props.icon ?
    props.theme.spacing.pageContent : props.theme.spacing.elementContent};
  border-radius: ${props => props.theme.radii.primary};
  color: ${props => props.theme.textColors.primary};
  font-size: ${props => props.theme.fonts.sizes.primary};
  font-weight: ${props => props.theme.fonts.weights.book};
  margin-bottom: 0;
  border: none;
  outline: none;
  backdrop-filter: ${props => props.theme.elementStyles.backdropFilter.blur};
  ${props => {
    if (navigator.userAgent.search('Firefox') > -1) {
      return css`background-color: ${props.theme.elementStyles.background.moz};`;
    } else {
      return css`background-color: ${props.theme.elementStyles.background.default};`;
    }
  }}
  &::placeholder {
    color: ${props => props.theme.textColors.placeholder};
  }
  &.error {
    border: 1px solid ${props => props.theme.colors.red};
    &::placeholder {
      color: ${props => props.theme.textColors.error};
    }
  }
  &[readonly] {
    pointer-events: none;
  }
  &[disabled]:not([readonly]) {
    color: ${props => props.theme.textColors.disabled};
    pointer-events: none;
  }
`;
const LoadingContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;
const TooltipContainer = styled(LoadingContainer)`
  top: 10px;
  cursor: pointer;
`;
interface InputProps {
  id: string
  name: string
  value: string
  type: string
  onChange?: any
  label?: string
  placeholder?: string
  error?: boolean
  touched?: boolean
  onBlur?: any
  onKeyUp?: any
  onKeyDown?: any
  autoComplete?: string
  readonly?: boolean
  disabled?: boolean
  showLoading?: boolean
  complete?: boolean
  validate?: any
  tooltip?: string
}

type Props = InputProps & IconProps

const Input: React.FC<Props> = (props: Props) => {

  const inputProps = Object.assign({
  }, props);
  let classes = '';
  if (inputProps.error && inputProps.touched) {
    classes += 'error';
  }

  const {
    id,
    name,
    label,
    showLoading,
    complete,
    validate,
    icon,
    iconPosition,
    tooltip,
  } = props;

  return (
    <Container>
      { label && (
        <InputLabel htmlFor={name}>{label}</InputLabel>
      )}
      <InputContainer icon={icon} iconPosition={iconPosition}>
        <Field
          as={InputField}
          {...inputProps}
          className={classes}
          validate={validate}
          icon={icon}
        />
      </InputContainer>
      { (!!tooltip && !showLoading) && (
        <>
          <TooltipContainer
            data-for={`question-${id}`}
            data-tip={tooltip}
          >
            <Img
              src={Question}
              alt="question mark"
              height="16"
            />
          </TooltipContainer>
          <ReactTooltip
            id={`question-${id}`}
            place="right"
            effect="solid"
            textColor="white"
            backgroundColor="black"
          />
        </>
      )}
      { (showLoading || complete) && (
        <LoadingContainer>
          <CircularLoading
            size={Size.SMALL}
            complete={complete}
          />
        </LoadingContainer>
      )}
    </Container>
  );
};
export default Input;