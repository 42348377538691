/**
 * The Quiz state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectJobState = (state: ApplicationRootState) => {
  return state.job || initialState;
};

// const selectJob = (id: String) =>
//   createSelector(selectJobState, substate => {
//     console.log('jobs ', substate.jobs);
//     return substate.jobs.length > 0 ? substate.jobs.filter((job) => job.id === id)[0] : undefined
//   })

const selectJob = () =>
  createSelector(selectJobState, substate => substate.selectedJob);

const selectJobs = () =>
  createSelector(selectJobState, substate => substate.jobs);

const selectLoading = () =>
  createSelector(selectJobState, substate => substate.loading);

const selectError = () =>
  createSelector(selectJobState, substate => substate.error);

export {
  selectJob,
  selectJobs,
  selectLoading,
  selectError
};