import {
  all, call, put, takeLatest 
} from 'redux-saga/effects';

// API
import * as jobApi from 'api/job';

import ActionTypes from './constants';


// MODELS
import { Job } from 'API';
import { GraphQLResult } from 'models/graphqlResult';
import {
  getJobFailure, getJobSuccess, listJobsFailure, listJobsSuccess 
} from './actions';

export function* getJob(action) {
  try {
    const response: GraphQLResult = yield call(jobApi.getJob, action.payload);
    const job: Job = response.data && response.data.getJob;
    yield put(getJobSuccess(job));
  } catch (err) {
    yield put(getJobFailure(err));
  }
}

/**
 * @param action payload is quiz ID
 */
export function* listJobs() {
  try {
    const response: GraphQLResult = yield call(jobApi.listJobs);
    const jobs: Job[] = response.data && response.data.listJobs.items;
    yield put(listJobsSuccess(jobs));
  } catch (err) {
    yield put(listJobsFailure(err));
  }
}

export default function* jobSaga() {
  yield all ([
    takeLatest(ActionTypes.GET_JOB, getJob),
    takeLatest(ActionTypes.LIST_JOBS, listJobs),
  ]);
}
