import * as Yup from 'yup';

export const applicationInfoValidation =
  Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    email: Yup.string()
      .required('Required')
      .email('Invalid email')
      .matches(/^\S*$/, 'Cannot contain spaces'),
    state: Yup.mixed()
      .required('Required'),
    jobId: Yup.mixed()
      .required('Required'),
    application: Yup.string()
      .required('Required'),
  });