import produce from 'immer';

import ActionTypes from './constants';
import {
  ContainerState, ContainerActions 
} from './types';

export const initialState: ContainerState = {
  loading: false,
  error: false,
  jobs: [],
};

const jobReducer = (
  state: ContainerState = initialState,
  action: ContainerActions
): ContainerState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.GET_JOB:
        draft.loading = true;
        draft.error = false;
        break;

      case ActionTypes.GET_JOB_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.selectedJob = action.payload;
        break;
      
      case ActionTypes.GET_JOB_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        console.log('error :>> ', action.payload);
        break;

      case ActionTypes.LIST_JOBS:
        draft.loading = true;
        draft.error = false;
        break;

      case ActionTypes.LIST_JOBS_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.jobs = action.payload;
        break;
      
      case ActionTypes.LIST_JOBS_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        console.log('error :>> ', action.payload);
        break;
      
      default:
        break;
    }
  });

export default jobReducer;