import ActionTypes from './constants';
import {
  ContainerState, ContainerActions 
} from './types';
import { Background } from 'constants/Background';

export const initialState: ContainerState = {
  background: Background.LIGHT,
};

function footerReducer(
  state: ContainerState = initialState,
  action: ContainerActions
): ContainerState {
  switch (action.type) {

    case ActionTypes.SET_FOOTER_BACKGROUND:
      return {
        background: action.payload,
      };

    default:
      return state;
  }
}

export default footerReducer;