import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from './App';
import reportWebVitals from './reportWebVitals';
import history from 'utils/history';

import configureStore from './configureStore';

// STYLESHEETS
import './index.css';

// AWS AMPLIFY
import Amplify from 'aws-amplify';
import conifg from './aws-exports';
Amplify.configure(conifg);

// ANALYTICS
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID === undefined ? 'GTM-WZJVJW7' : process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

// STORE
const initialState = {
};
const store = configureStore(initialState, history);

// ROOT
const MOUNT_NODE = document.getElementById('root');
const Main = (
  <Provider store={store}>
    {/* LanguageProvider here */}
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(Main, MOUNT_NODE);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
