import { action } from 'typesafe-actions';
import ActionTypes from './constants';

// MODELS
import Error from 'models/error';
import JobApplication from 'models/JobApplication';
import { CreateApplicationInput } from 'API';

export const setApplicationData = (application: JobApplication) =>
  action(ActionTypes.SET_JOB_APPLICATION_DATA, application);

export const submitApplicationData = (application: CreateApplicationInput) =>
  action(ActionTypes.SUBMIT_JOB_APPLICATION, application);

export const submitApplicationDataSuccess = (application: JobApplication) =>
  action(ActionTypes.SUBMIT_JOB_APPLICATION_SUCCESS, application);

export const submitApplicationDataFailure = (error: Error) =>
  action(ActionTypes.SUBMIT_JOB_APPLICATION_FAILURE, error);