import React from 'react';

// STYLES
import styled from 'styles/styled-components';
import media, {
  max, breaks 
} from 'styles/breakpoints';

// CONSTANTS
import { Background } from 'constants/Background';
import { Position } from 'constants/Position';

// ASSETS
import lightQ from 'assets/svgs/white-top-logo-wshadow.svg';
import darkQ from 'assets/svgs/gray-top-logo-wshadow.svg';

interface HeaderProps {
  left?: any
  center?: any
  menuItems?: any
  iconPosition?: Position
  background?: Background
}

const HeaderElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100px;
  padding: 0 2rem;
  z-index: 10;

  ${max(breaks.laptop.max)} {
    height: 72px;
    padding: 0 1rem;
  }
`;
const Groups = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
const HeaderIcon = styled.img`
  width: ${props => props.theme.header.icon.width};
  height: auto;

  /* ${media.laptop} {
    width: 36px;
  } */

  ${max(breaks.laptop.max)} {
    display: none;
  }
`;
const Left = styled.div`
  display: flex;
  flex: 1;
`;
const Center = styled.div`
  margin: 0 8px;

  ${max(breaks.tablet.max)} {
    display: none;
  }
`;

const Header: React.FC<HeaderProps> = ({
  left,
  center,
  iconPosition,
  menuItems,
  background = Background.LIGHT,
}: HeaderProps) => {
  return (
    <HeaderElement>
      <Groups>
        <Left>
          { (!left && iconPosition === Position.LEFT) && (
            <HeaderIcon src={background === Background.LIGHT ? darkQ : lightQ} />
          )} { !!left && (
            left
          )}
        </Left>
        <Center>
          { (!center && iconPosition === Position.CENTER) && (
            <HeaderIcon src={background === Background.LIGHT ? darkQ : lightQ} />
          )} { !!center && (
            center
          )}
        </Center>
      </Groups>
    </HeaderElement>
  );
};
export default Header;