import {
  API, graphqlOperation 
} from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

// API
import {
  getJob as get,
  listJobs as list
} from 'graphql/queries';

/**
 * 
 * @param id 
 */
export const getJob = (id: string) => 
  API.graphql(graphqlOperation(get, {
    id, 
  }));

/**
 * 
 */
export const listJobs = () =>
  API.graphql({
    query: list,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  });
