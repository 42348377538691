import styled from 'styles/styled-components';

interface ButtonSectionProps {
  bottom?: string;
}

export default styled.div<ButtonSectionProps>`
  z-index: 1;
  display: flex;
  justify-content: center;
`;