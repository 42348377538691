import {
  call, put, takeLatest, all 
} from 'redux-saga/effects';
import toast  from 'react-hot-toast';

// API
import * as applicationApi from 'api/application';

import ActionTypes from './constants';
import {
  submitApplicationDataSuccess,
  submitApplicationDataFailure
} from './actions';

// MODELS
import { CreateApplicationInput } from 'API';
import { GraphQLResult } from 'models/graphqlResult';
import JobApplication from 'models/JobApplication';

/**
 * @param action payload is quiz ID
 */
export function* submitApplication(action) {
  try {
    const application: CreateApplicationInput = action.payload;
    const response: GraphQLResult = yield call(applicationApi.createApplication, application);
    const jobApplication: JobApplication = response.data.createApplication;
    yield put(submitApplicationDataSuccess(jobApplication));
    toast.success('Application Submitted');
  } catch (err) {
    yield put(submitApplicationDataFailure(err));
    toast.error('Application Submission Failure');
  }
}

export default function* applicationSaga() {
  yield all([
    yield takeLatest(ActionTypes.SUBMIT_JOB_APPLICATION, submitApplication),
  ]);
}
