/**
 * The footer state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';

const selectFooter = (state: ApplicationRootState) => {
  return state.footer;
};

const selectBackground = () =>
  createSelector(selectFooter, substate => substate.background);

export {
  selectFooter,
  selectBackground
};