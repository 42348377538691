import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// STYLES
import styled from 'styles/styled-components';

// STYLED COMPONENTS
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ButtonText = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.elements.button.small.height};
  font-weight: ${props => props.theme.fonts.weights.primary};

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  // showText?: boolean
  // neighborHeight?: string
}

const BackButton: React.FC<Props> = () => {
  const history = useHistory();

  const back = useCallback((): void => {
    history.goBack();
  }, [
    history,
  ]);

  return (
    <Container
      onClick={back}
    >
      {/* <Left
        itemHeight={neighborHeight}
      /> */}
      {/* { showText && ( */}
      <ButtonText>Back</ButtonText>
      {/* )} */}
    </Container>
  );
};
export default BackButton;

