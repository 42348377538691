import styled, { keyframes } from 'styles/styled-components';

// CONSTANTS
import { Align } from 'constants/Align';

const alignment = (align: Align = Align.LEFT) => {
  switch (align) {
    case Align.RIGHT:
      return 'right';
    case Align.LEFT:
      return 'left';
    case Align.CENTER:
    default:
      return 'center';
  }
};


interface Props {
  align?: Align
}

export const ellipsis = keyframes`
  from {
    width: 0;
    margin-right: 18px;
  }
  to {
    width: 18px; 
    margin-right: 0;   
  }
`;
export default styled.div<Props>`
  text-align: ${({ align }) => alignment(align)};
  height: 22px;
  margin-top: ${props => props.theme.spacing.between};

  &::after {
    content: '\\2026';
    display: inline-block;
    vertical-align: bottom;
    margin-left: 2px;
    overflow: hidden;
    -webkit-animation: ${ellipsis} steps(4,end) 1s infinite;      
    animation: ${ellipsis} steps(4,end) 1s infinite;
  }
`;