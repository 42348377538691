import React from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

// STYLES
import styled from 'styles/styled-components';
import {
  max, breaks 
} from 'styles/breakpoints';

// UTILS
import ROUTES from 'utils/routes';

// SHARED
import { selectBackground } from 'shared/footer/selectors';
import { RootState } from 'shared/footer/types';

// CONSTANTS
import { Background } from 'constants/Background';

// STYLED COMPONENTS
interface FooterProps {
  background?: Background
}

const Container = styled.div<FooterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.footer.width};
  height: ${props => props.theme.footer.height};
  background-color: ${props => props.background === Background.LIGHT ?
    props.theme.colors.white : props.theme.colors.background};
  color: ${props => props.theme.textColors.black};

  ${max(breaks.tablet.max)} {
    flex-direction: column-reverse;
  }
`;
const Content = styled.span`
  margin-right: ${props => props.theme.spacing.between};
`;
const FooterLink = styled.a`
  color: ${props => props.theme.textColors.black};
  text-decoration: none;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: ${props => props.theme.spacing.between};
  }

  &:hover {
    text-decoration: underline;
  }
`;
const FooterLinksContainer = styled.div`
  display: flex;

  ${max(breaks.tablet.max)} {
    margin-bottom: 0.5rem;
  }
`;

interface FooterSelection {
  background: Background
}

const stateSelector = createStructuredSelector<RootState, FooterSelection>({
  background: selectBackground(),
});

const Footer: React.FC = () => {

  const year = new Date().getFullYear();
  const { background } = useSelector(stateSelector);

  return (
    <Container background={background}>
      <Content>NextIQ &copy; {year}</Content>
      <FooterLinksContainer>
        <FooterLink
          href={ROUTES.privacy}
        >
          Privacy Policy
        </FooterLink>
        <FooterLink
          href={ROUTES.terms}
        >
          Terms of Use
        </FooterLink>
        {/* <FooterLink
          href={ROUTES.faq}
        >
          FAQ
        </FooterLink> */}
      </FooterLinksContainer>
    </Container>
  );
};
export default Footer;