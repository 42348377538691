import React from 'react';
import {
  Switch, Route 
} from 'react-router-dom';

// STYLES
import styled, { ThemeProvider } from 'styles/styled-components';

// UTILS
import ROUTES from 'utils/routes';
import { Toaster } from 'react-hot-toast';

// PAGES
import LandingPage from 'pages/Landing';
import theme from 'styles/light-theme';
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy';
import TermsAndConditions from 'pages/Legal/TermsOfUse';


// STYLED COMPONENTS
const AppWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: #fff;
`;

const App: React.FC = () => {

  return (
    <AppWrapper>
      <Toaster/>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path={ROUTES.landing} component={LandingPage} />
          <Route exact path={ROUTES.privacy} component={PrivacyPolicy} />
          <Route exact path={ROUTES.terms} component={TermsAndConditions} />
          {/* <Route exact path={CONTENT_ROUTES.job} component={JobPage} />
          <Route exact path={CONTENT_ROUTES.application} component={ApplicationPage} /> */}
        </Switch>
      </ThemeProvider>
    </AppWrapper>
  );
};

export default App;