import React, { BaseSyntheticEvent } from 'react';
import {
  lighten, darken 
} from 'polished';

// STYLES
import styled, { css } from 'styles/styled-components';
import {
  max, breaks 
} from 'styles/breakpoints';
import theme from 'styles/light-theme';

// COMPONENTS
// import Img from 'components/Img'

// CONSTANTS
import { Background } from 'constants/Background';
import { Size } from 'constants/Size';
import { FlattenSimpleInterpolation } from 'styled-components';

const sizing = (size: Size = Size.SMALL) => {
  switch (size) {
    case Size.MEDIUM:
      return theme.elements.button.medium.width;
    case Size.LARGE:
    case Size.XLARGE:
      return theme.elements.button.large.width;
    case Size.SMALL:
    default:
      return theme.elements.button.small.width;
  }
};

interface UploadButtonProps extends React.HTMLProps<HTMLButtonElement> {
  size?: Size
  secondary?: boolean
  accent?: boolean
  link?: boolean
  success?: boolean
  danger?: boolean
  filled?: boolean
  disabled?: boolean
  background?: Background
}

interface UploadInputProps extends React.HTMLProps<HTMLInputElement> {
  inputId: string
}

const Button = styled.div<UploadButtonProps>`
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: 'row';
    justify-content: center;
    align-items: center;
    width: ${({ size }) => sizing(size)};
    height: ${props => props.theme.elements.button.small.height};
    border-width: ${props => props.theme.borders.width.primary};
    border-color: ${props => props.theme.borders.colors.primary};
    border-style: solid;
    border-radius: ${props => props.theme.radii.primary};
    font-size: ${props => props.theme.fonts.sizes.small};
    font-weight: ${props => props.theme.fonts.weights.primary};
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.textColors.white};
    cursor: pointer;
    outline: none;

    input {
        position: absolute;
        top: 0;
        right: 0; /* not left, because only the right part of the input seems to
                    be clickable in some browser I can't remember */
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0); /* and all the other old opacity stuff you
                                    want to support */
        font-size: 300px; /* wtf, but apparently the most reliable way to make
                            a large part of the input clickable in most browsers */
        height: 200px;
    }

    &:hover {
    background-color: ${props => lighten(0.1, props.theme.colors.primary)};
    border-color: ${props => lighten(0.1, props.theme.borders.colors.primary)};
  }

  ${props => {
    let styles: FlattenSimpleInterpolation = css``;
    if (props.secondary) {
      styles = css`
        ${styles}
        background-color: transparent;
        border-color: ${(props.background && props.background) === Background.LIGHT ?
    props.theme.borders.colors.secondary : props.theme.borders.colors.white};
        color: ${(props.background && props.background) === Background.LIGHT ?
    props.theme.textColors.primary : props.theme.textColors.white};
    
        &:hover {
          background-color: ${(props.background && props.background) === Background.LIGHT ?
    'transparent' : 'rgba(255,255,255,0.2)'};
          color: ${(props.background && props.background) === Background.LIGHT ?
    lighten(0.4, props.theme.textColors.primary) : props.theme.textColors.white};
          border-color: ${(props.background && props.background) === Background.LIGHT ?
    lighten(0.4, props.theme.borders.colors.secondary) : props.theme.borders.colors.white};
        }
      `;
    }

    if (props.success) {
      styles = css`
        ${styles}
        background-color: ${props.theme.colors.green};
        border-color: ${props.theme.colors.green};
        color: ${props.theme.textColors.white};

        &:hover {
          background-color: ${lighten(0.025, props.theme.colors.green)};
          border-color: ${lighten(0.025, props.theme.colors.green)};
        }
      `;
    }

    if (props.success) {
      styles = css`
        ${styles}
        background-color: ${props.theme.colors.green};
        border-color: ${props.theme.colors.green};
        color: ${props.theme.textColors.white};

        &:hover {
          background-color: ${lighten(0.025, props.theme.colors.green)};
          border-color: ${lighten(0.025, props.theme.colors.green)};
        }
      `;
    }

    if (props.success) {
      styles = css`
        ${styles}
        background-color: ${props.theme.colors.green};
        border-color: ${props.theme.colors.green};
        color: ${props.theme.textColors.white};

        &:hover {
          background-color: ${lighten(0.025, props.theme.colors.green)};
          border-color: ${lighten(0.025, props.theme.colors.green)};
        }
      `;
    }
    if (props.accent) {
      styles = css`
        ${styles}
        background-color: ${props.filled ? props.theme.colors.orange : 'transparent'};
        border-color: ${props.theme.colors.orange};
        color: ${props.filled ? props.theme.textColors.white : props.theme.colors.orange};

        &:hover {
          background-color: ${props.filled ? lighten(0.1, props.theme.colors.orange) : 'transparent'};
          border-color: ${lighten(0.1, props.theme.colors.orange)};
          color: ${props.filled ? props.theme.textColors.white : lighten(0.1, props.theme.colors.orange)};
        }
      `;
    }

    if (props.danger) {
      styles = css`
        ${styles}
        background-color: ${props.theme.colors.red};
        border-color: ${props.theme.colors.red};
        color: ${props.theme.textColors.white};

        &:hover {
          background-color: ${lighten(0.1, props.theme.colors.red)};
          border-color: ${lighten(0.1, props.theme.colors.red)};
        }
      `;
    }

    if (props.link) {
      styles = css`
        ${styles}
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        color: ${props.danger ?
    props.theme.textColors.error : props.theme.textColors.link};
        padding: 0;
    
        &:hover {
          background-color: transparent;
          text-decoration: underline;
          cursor: pointer;
        }
      `;
    }

    if (props.disabled) {
      styles = css`
        ${styles}
        background-color: transparent;
        border-color: ${props.theme.colors.disabled};
        color: ${props.theme.textColors.disabled};
        opacity: 0.8;
        cursor: default;
        pointer-events: none;
      `;
    }

    return styles;
  }}

  &.gray {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.textColors.primary};

    &:hover {
      background-color: ${props => darken(0.1, props.theme.colors.background)};
      border-color: ${props => darken(0.1, props.theme.colors.background)};
    }
  }

  &.white {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.textColors.black};
  }

  ${max(breaks.laptop.max)} {
    height: 36px;
  }
`;

const Input = styled.input`

`;

const Container = styled.div`
  display: flex;
  align-items: center;

`;

const Filename = styled.p`
  margin: 0 0 0 10px;
`;

const onChangeEvent = (e: BaseSyntheticEvent) => {
  const pFilename = document.getElementById('pFilename');
  const file: File = e.target.files[0];
  pFilename && (pFilename.innerText! = file.name);
};

type Props = UploadButtonProps & UploadInputProps

const UploadButton: React.FC<Props> = ({
  children,
  inputId,
  onChange,
  ...rest
}: Props) => {
  const buttonProps = {
    size: rest.size,
    secondary: rest.secondary,
    accent: rest.accent,
    link: rest.link,
    success: rest.success,
    danger: rest.danger,
    filled: rest.filled,
    disabled: rest.disabled,
    background: rest.background || Background.LIGHT,
    className: rest.className,
  };

  return ( 
    <Container>
      <Button className="fileupload" {...buttonProps}>
        <Input id={inputId} name="application" type="file" onChange={(e) => {onChange && onChange(e); onChangeEvent(e);}} />
        {children}
      </Button>
      <Filename id="pFilename"></Filename>
    </Container>
  );
};
export default UploadButton;