export const CertificationOptions = [
  {
    label: 'Master Electrician',
    value: 'master_electrician', 
  },
  {
    label: 'AEE',
    value: 'aee', 
  },
  {
    label: 'Nabcep',
    value: 'nabcep', 
  },
  {
    label: 'NextIQ',
    value: 'nextiq', 
  },
];