/**
 * Create the store with dynamic reducers
 */

import {
  applyMiddleware, createStore 
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import {
  InjectedStore, ApplicationRootState 
} from 'types';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore(initialState: ApplicationRootState | any, history: History) {
  const reduxSagaMonitorOptions = {
  };
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
  ];

  let enhancer = applyMiddleware(...middlewares);

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    enhancer = composeWithDevTools(enhancer);
  }

  // NOTE: Uncomment the code below to restore support for Redux Saga
  // Dev Tools once it supports redux-saga version 1.x.x
  // if (window.__SAGA_MONITOR_EXTENSION__)
  //   reduxSagaMonitorOptions = {
  //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
  //   };


  const store = createStore(
    createReducer(),
    initialState,
    enhancer
  ) as InjectedStore;

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {
  }; // Reducer registry
  store.injectedSagas = {
  }; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
