import React, {
  useEffect, useRef 
} from 'react';
import { createStructuredSelector } from 'reselect';
import {
  useDispatch, useSelector 
} from 'react-redux';

import { Storage } from 'aws-amplify';

import Helmet from 'react-helmet';

// STYLES
import styled from 'styles/styled-components';
import theme from 'styles/light-theme';
import './index.css';

// SAGA
import { useInjectSaga } from 'utils/injectSaga';

// APPLICATIONS
import jobApplicationSaga from 'containers/JobApplication/saga';
import { submitApplicationData } from 'containers/JobApplication/actions';
import {
  selectSuccess as selectJobApplicationSuccess,
  selectError as selectJobApplicationError,
  selectJobApplication
} from 'containers/JobApplication/selectors';
import JobApplication from 'models/JobApplication';

// JOBS
import { listJobs } from 'containers/Job/actions';
import jobsSaga from 'containers/Job/saga';
import {
  selectJobs,
  selectLoading as selectJobLoading,
  selectError as selectJobError
} from 'containers/Job/selectors';

// APP
import { setGlobalLoading } from 'containers/App/actions';
import { RootState } from 'containers/App/types';

// SHARED
import { openModal } from 'shared/modal/actions';
import { setFooterBackground } from 'shared/footer/actions';

// COMPONENTS
import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import Section from 'components/Section';
import SectionButtonContainer from 'components/containers/SectionButtonContainer';
import UniversalTitle from 'components/UniversalTitle';
import VideoHero from 'components/Hero/Video';
import Modal from 'components/Modal';

// MODELS
import Error from 'models/error';

// CONSTANTS
import { Align } from 'constants/Align';
import { Background } from 'constants/Background';
import { Size } from 'constants/Size';
import {
  CreateApplicationInput, Job 
} from 'API';
import Footer from 'components/Footer';
import JobCards from 'components/JobCards';
import { ApplicationModalId } from 'shared/modal/constants';
import TipsAndTricks from 'components/TipsAndTricks';

// ASSETS
import StartAnimation from 'assets/gifs/start-full-transparent.gif';
import ApplyAnimation from 'assets/gifs/apply-full-transparent.gif';
import ConnectAnimation from 'assets/gifs/connect-full-transparent.gif';

// ANALYTICS
import TagManager from 'react-gtm-module';

import VimeoPlayer from 'components/VimeoPlayer';
import Endorsements from 'components/Endorsements';
import { Formik } from 'formik';
import { applicationInfoValidation } from 'components/Form/FormValidation';
import Form from 'components/Form/new/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import FieldError from 'components/Form/new/Error';
import SelectInput from 'components/Form/new/Select';
import { StateOptions } from 'constants/State';
import CreateableSelect from 'components/Form/CreatableSelect';
import { CertificationOptions } from 'constants/Certification';
import UploadButton from 'components/Button/Upload';
import {
  makeSelectSelectedJobCategory, makeSelectSelectedJobId 
} from 'shared/modal/selectors';


// INTERFACES
interface LandingProps {

}
interface Certification {
  label: string
  value: string
}

interface FormValues {
  name: string
  email: string
  state: any
  jobId: any
  certifications: Certification[]
}

interface JobsSelection {
  jobs: Job[] | undefined,
  loading: boolean,
  error: Error | boolean,
}

interface ModalSelection {
  selectedJobId?: string
  selectedJobCategory?: string
}

interface ApplicationSelection {
  jobApplication?: JobApplication,
  submitApplicationSuccess: boolean,
  error: Error | boolean,
}

// STYLED COMPONENTS

const StepsSection = styled.div`
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(130px, 2fr));
  justify-items: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  color: ${props => props.theme.textColors.black};
  text-align: center;

  div.orangeLine {
    font-size: ${theme.fonts.sizes.xlarge};
    font-weight: ${theme.fonts.weights.primary};
    padding-top: 12px;
    border-top: 4px solid orange;
    color: black;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  img {
    width: 120px;
    height: 120px;
  }
`;

const InputResumeContainer = styled.div`
  display: flex;
  align-items: center;
`;

// SELECTORS

const JobsSelector = createStructuredSelector<RootState, JobsSelection>({
  jobs: selectJobs(),
  loading: selectJobLoading(),
  error: selectJobError(),
});

const JobApplicationsSelector = createStructuredSelector<RootState, ApplicationSelection>({
  jobApplication: selectJobApplication(),
  submitApplicationSuccess: selectJobApplicationSuccess(),
  error: selectJobApplicationError(),
});

const ModalSelector = createStructuredSelector<RootState, ModalSelection>({
  selectedJobId: makeSelectSelectedJobId(),
  selectedJobCategory: makeSelectSelectedJobCategory(),
});

// CONSTANTS

const nameInputId = 'nameInputId';
const emailInputId = 'emailInputId';
const stateSelectId = 'stateSelectId';
const jobCategorySelectId = 'jobCategorySelectId';
const certificationsSelectId = 'certificationsSelectId';
const resumeInputId = 'resumeInputId';

const Landing: React.FC<LandingProps> = () => {
  const dispatch = useDispatch();

  const userInfoFormRef = useRef<HTMLFormElement | any>();

  useInjectSaga({
    key: 'job',
    saga: jobsSaga, 
  });
  useInjectSaga({
    key: 'jobApplication',
    saga: jobApplicationSaga,
  });

  const { jobs } = useSelector(JobsSelector);

  const { jobApplication, submitApplicationSuccess } = useSelector(JobApplicationsSelector);

  const { selectedJobId, selectedJobCategory } = useSelector(ModalSelector);

  /**
   * Load jobs
   * Set footer background
   */
  useEffect(() => {
    dispatch(setFooterBackground(Background.LIGHT));
  }, [ 
    dispatch, 
  ]);

  useEffect(() => {
    dispatch(listJobs());
  }, [ 
    dispatch, 
  ]);

  /**
   * Global loading variable
   */
  useEffect(() => {
    if (jobs) {
      dispatch(setGlobalLoading(false));
    }
  }, [ 
    jobs,
    dispatch, 
  ]);

  /**
   * Application created
   */
  useEffect(() => {
    if (submitApplicationSuccess && jobApplication) {
      TagManager.dataLayer({
        dataLayer: {
          applicationId: jobApplication.id,
        },
      });
    }
  }, [
    jobApplication,
    submitApplicationSuccess, 
  ]);

  const openApplicationModal = (): void => {
    dispatch(openModal(ApplicationModalId, undefined, undefined));
  };

  const onSave = () => {
    userInfoFormRef.current?.submitForm();
  };

  const sendEmail = async (values: FormValues) => {
    const inputResume: HTMLInputElement = document.getElementById(resumeInputId) as HTMLInputElement;

    const s3ResumePrefix = 'public/';

    let file: File;
    if (inputResume.files && inputResume.files.length > 0) {
      file = inputResume.files[0];
    } else {
      throw new Error('Missing resume');
    }

    try {
      if (file) {
        const result: any = await Storage.put(file.name, file);
        if (result.key === file.name) {
          const application: CreateApplicationInput = {
            name: values.name,
            email: values.email,
            state: values.state,
            jobID: values.jobId,
            certifications: values.certifications.map(certification => certification.label),
            resume: `${s3ResumePrefix}${file.name}`,
          };
          dispatch(submitApplicationData(application));
        }
      }
    } catch (e) {
      console.error('Error uploading file: ', e);
    }
  };

  return (
    <>
      <Helmet>
        <title>NextIQ - Careers</title>
        <meta
          name="description"
          content="NextIQ Careers"
        />
      </Helmet>
      <PageContainer>
        <VideoHero vimeoId={569960026}>
          <UniversalTitle
            title="Join the renewables revolution"
            subtitle="Bring your technical expertise and drive what’s next in the industry"
            secondary={theme.textColors.white}
            align={Align.CENTER}
            margin="0 5rem 5rem 5rem"
            primary={theme.textColors.white}
            size={Size.XLARGE}
            textAlign={Align.CENTER}
            className="utPageTitle"
          />
          <SectionButtonContainer
            bottom="100px"
          >
            <Button
              accent
              filled
              size={Size.LARGE}
              onClick={openApplicationModal}
            >
              Apply
            </Button>
          </SectionButtonContainer>
        </VideoHero>
        <Section background={Background.DARK} flex>
          <UniversalTitle
            title={'Let\'s get started'}
            marginBottom="2rem"
            align={Align.CENTER}
            textAlign={Align.CENTER}
          />
          <StepsSection>
            <Step>
              <img src={StartAnimation} />
              <div className="orangeLine"></div>
              <UniversalTitle
                title="Start"
                align={Align.CENTER}
                primary={theme.textColors.black}
                size={Size.XLARGE}
                textAlign={Align.CENTER}
              />
              <p>Select your career</p>
            </Step>
            <Step>
              <img src={ApplyAnimation} />
              <div className="orangeLine"></div>
              <UniversalTitle
                title="Apply"
                align={Align.CENTER}
                primary={theme.textColors.black}
                size={Size.XLARGE}
                textAlign={Align.CENTER}
              />
              <p>Join our career hub</p>
            </Step>
            <Step>
              <img src={ConnectAnimation} />
              <div className="orangeLine"></div>
              <UniversalTitle
                title="Connect"
                align={Align.CENTER}
                primary={theme.textColors.black}
                size={Size.XLARGE}
                textAlign={Align.CENTER}
              />
              <p>Receive opportunities tailored to you</p>
            </Step>
          </StepsSection>
        </Section>
        <Section background={Background.LIGHT} flex>
          <UniversalTitle
            title={'Connect with top companies'}
            marginBottom="4rem"
            align={Align.CENTER}
            textAlign={Align.CENTER}
          />
          <Endorsements />
        </Section>
        <Section background={Background.DARK} flex>
          <UniversalTitle
            title="Your future career awaits"
            marginBottom="4rem"
            align={Align.CENTER}
          />
          {jobs && <JobCards jobs={jobs}></JobCards>}
        </Section>
        <Section background={Background.LIGHT} full flex>
          <UniversalTitle
            title="Tips and Tricks"
            marginBottom="2rem"
            align={Align.CENTER}
            textAlign={Align.CENTER}
          />
          <TipsAndTricks></TipsAndTricks>
          <SectionButtonContainer>
            <Button
              accent
              size={Size.MEDIUM}
              onClick={openApplicationModal}
              filled
            >
              Apply
            </Button>
          </SectionButtonContainer>
        </Section>
        <Section background={Background.DARK} full flex centered>
          <UniversalTitle
            title="NextIQ"
            marginBottom="2rem"
            align={Align.CENTER}
          />
          <VimeoPlayer vimeoId={463258820} placeholderImage={StartAnimation}></VimeoPlayer>
        </Section>
        <Footer />
      </PageContainer>
      <Modal id={ApplicationModalId} title={'Application'} onSave={() => onSave()} saveText="Submit">
        <Formik
          innerRef={userInfoFormRef}
          initialValues={{
            name: '',
            email: '',
            state: '',
            jobId: (selectedJobCategory && selectedJobId) ? {
              label: selectedJobCategory,
              value: selectedJobId, 
            } : '',
            certifications: [],
            application: undefined,
          }}
          validationSchema={applicationInfoValidation}
          onSubmit={sendEmail}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <FormGroup>
                <Input
                  id={nameInputId}
                  type="text"
                  label="Name"
                  name="name"
                  value={values.name}
                  error={!!errors.name}
                  touched={!!touched.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {(errors && errors.name && touched.name) && (
                  <FieldError error={errors.name} />
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  id={emailInputId}
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  error={!!errors.email}
                  touched={!!touched.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {(errors && errors.email && touched.email) && (
                  <FieldError error={errors.email} />
                )}
              </FormGroup>
              <FormGroup>
                <SelectInput
                  id={stateSelectId}
                  name="state"
                  placeholder="Select State..."
                  options={StateOptions}
                  onChange={state => setFieldValue('state', state)}
                />
                { (errors && errors.state && touched.state) && (
                  <FieldError error={errors.state} />
                )}
              </FormGroup>
              <FormGroup>
                <SelectInput
                  id={jobCategorySelectId}
                  name="jobId"
                  value={{
                    label: selectedJobCategory,
                    value: selectedJobId,
                  }}
                  placeholder={'Select Job Category...'}
                  options={jobs && jobs.map((job, _) => {
                    return {
                      label: job.job_category,
                      value: job.id,
                    };
                  })}
                  onChange={jobId => setFieldValue('jobId', jobId)}
                  defaultValue={(selectedJobCategory && selectedJobId) ? {
                    label: selectedJobCategory,
                    value: selectedJobId, 
                  } : undefined}
                />
                { (errors && errors.jobId && touched.jobId) && (
                  <FieldError error={errors.jobId} />
                )}
              </FormGroup>
              <FormGroup>
                <CreateableSelect multi
                  id={certificationsSelectId}
                  label="Certifications"
                  name="certifications"
                  placeholder="Select or create new tag"
                  options={CertificationOptions}
                  noOptionsMsg="Type to create another tag"
                  onChange={certifications => setFieldValue('certifications', certifications)}
                />
              </FormGroup>
              <FormGroup>
                <InputResumeContainer>
                  <UploadButton name="application" inputId={resumeInputId} onChange={() => setFieldValue('application', 'application')}>
                    Upload Resume
                  </UploadButton>
                  { (errors && errors.application && touched.application) && (
                    <FieldError error={errors.application} />
                  )}
                </InputResumeContainer>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default Landing;