import React from 'react';
import styled from 'styles/styled-components';

interface Props {
  error?: any
}

const FieldErrorMessage = styled.div`
  width: 100%;
  margin-top: 4px;
  font-size: ${props => props.theme.fonts.sizes.small};
  color: ${props => props.theme.textColors.error};
  text-align: center;
`;

const FieldError: React.FC<Props> = ({ error }: Props) => {
  if (!error) {
    return null;
  }
  return <FieldErrorMessage>{error}</FieldErrorMessage>;
};

export default FieldError;