// https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8#gistcomment-3168746

import React from 'react';
import Select, {
  ActionMeta, OptionTypeBase, Props as SelectProps 
} from 'react-select';
import { useField } from 'formik';

import theme from 'styles/light-theme';

interface Props {
  defaultVal?: OptionTypeBase | readonly OptionTypeBase[] | null | undefined
}

interface StyleProps {
  width?: number
  marginBottom?: number
  border?: string
}

interface FormProps extends SelectProps<OptionTypeBase> {
  id?: string
  name: string
  placeholder: string
  error?: string
}

type SelectInputProps = FormProps & StyleProps & Props

const SelectInput: React.FC<SelectInputProps> = ({
  id,
  name,
  options,
  placeholder,
  onChange,
  loading,
  title,
  error,
  width,
  marginBottom,
  border,
  defaultValue,
}: SelectInputProps) => {
  const [
    field,, helpers,
  ] = useField<string>(name);

  const background = theme.colors.white;
  const borderRadius = theme.radii.primary;
  const outline = 'none';
  const borderNone = 'none';
  const customStyles = {
    container: provided => ({
      ...provided,
      outline: 'none',
      width: width || 'auto',
      marginBottom: marginBottom || '0',
      border: borderNone,
    }),
    control: (provided, state) => {
      const flex = '1';
      return {
        ...provided,
        flex,
        background,
        borderRadius,
        outline,
      };
    },
    placeholder: provided => ({
      ...provided,
      color: 'grey',
    }),
    menu: provided => {
      return {
        ...provided,
        background,
        border: borderNone,
        borderRadius,
        outline,
      };
    },
    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    noOptionsMessage: provided => ({
      ...provided,
      color: theme.textColors.black,
      textAlign: 'left',
    }),

  };

  return (
    <Select
      id={id}
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option, action: ActionMeta<OptionTypeBase>): void => {
        helpers.setValue(option.value);
        if (onChange) {
          onChange(option.value, action);
        }
      }}
      onBlur={field.onBlur}
      placeholder={placeholder}
      styles={customStyles}
      menuPortalTarget={document.body}
      menuPlacement={'auto'}
      isLoading={loading}
      defaultValue={defaultValue}
    />
  );
};
export default SelectInput;