import { action } from 'typesafe-actions';
import ActionTypes from './constants';

// MODELS
import Error from 'models/error';
import { Job } from 'API';

export const getJob = (id: string) =>
  action(ActionTypes.GET_JOB, id);

export const getJobSuccess = (job: Job) =>
  action(ActionTypes.GET_JOB_SUCCESS, job);

export const getJobFailure = (error: Error) =>
  action(ActionTypes.GET_JOB_FAILURE, error);

export const listJobs = () =>
  action(ActionTypes.LIST_JOBS);

export const listJobsSuccess = (jobs: Job[]) =>
  action(ActionTypes.LIST_JOBS_SUCCESS, jobs);

export const listJobsFailure = (error: Error) =>
  action(ActionTypes.LIST_JOBS_FAILURE, error);