import styled from 'styles/styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  ${props => {
    return `min-height: calc(100vh - ${props.theme.footer.height})`;
  }}
`;
export default PageContainer;