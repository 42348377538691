import React from 'react';

// STYLES
import styled from 'styles/styled-components';
import {
  max, breaks 
} from 'styles/breakpoints';

// CONSTANTS
import { Background } from 'constants/Background';

interface SectionProps {
  background: Background
  children?: any
  full?: boolean
  skinny?: boolean
  flex?: boolean
  short?: boolean
  centered?: boolean
  padded?: boolean
}
const StyledSection = styled.section<SectionProps>`
  position: relative;
  display: flex;
  width: 100vw;
  background-color: ${props => {
    return props.background === Background.DARK ? 
      props.theme.colors.background : props.theme.colors.white;
  }};
  padding-top: 80px;
  padding-right: 
    max(${props => props.padded ? '3.5rem' : '2rem'}, 
    calc((100vw - (${props => props.skinny ?
    props.theme.nav.width : props.theme.page.width})) / 2));
  padding-left:
    max(${props => props.padded ? '3.5rem' : '2rem'},
    calc((100vw - (${props => props.skinny ?
    props.theme.nav.width : props.theme.page.width})) / 2));
  ${props => {
    let styles = '';
    styles += props.short ? `
      padding-bottom: 80px;
    ` : `
      padding-bottom: 120px;
    `;
    styles += props.full ? `
      flex: 1;
    ` : `
      flex: initial;
    `;
    if (props.flex) {
      styles += `
        display: flex;
        flex-direction: column;
      `;
      if (props.centered) {
        styles += `
          align-items: center;
        `;
      }
    }
    return styles;
  }}

  ${max(breaks.tablet.max)} {
    padding-right: 2rem;
    padding-left: 2rem;
    // padding: 2rem;
  }
`;
const Section: React.FC<SectionProps> = ({
  background = Background.LIGHT,
  children,
  full = false,
  skinny = false,
  flex = false,
  short = false,
  centered = false,
  padded = false,
}: SectionProps) => {
  return (
    <StyledSection
      background={background}
      full={full}
      skinny={skinny}
      flex={flex}
      short={short}
      centered={centered}
      padded={padded}
    >
      {children}
    </StyledSection>
  );
};

export default Section;