import React from 'react';
import styled from 'styles/styled-components';
import { ErrorMessage } from 'formik';

interface Props {
  name: string
}

const FieldErrorMessage = styled(ErrorMessage)`
  font-size: ${props => props.theme.fonts.sizes.small};
  color: ${props => props.theme.textColors.error};
  margin-top: 8px;
  // position: absolute;
  // bottom: calc((${props => props.theme.spacing.belowFormInput} - 4px) * -1);
`;

const FieldError = (props: Props) => {
  const { name } = props;
  return <FieldErrorMessage name={name} component="div" className="error-message" />;
};

export default FieldError;