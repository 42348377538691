/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://dlvdwqromfezba33m2yle5h5zi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-west-2:df09aabd-a999-411c-b11d-2aca425cc903",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_UaziIODGM",
    "aws_user_pools_web_client_id": "3ief9694tkbkvdd37noprfo10o",
    "oauth": {},
    "aws_user_files_s3_bucket": "careers-resumes14829-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
