import React from 'react';

// REACT CAROUSEL
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// STYLES
import styled from 'styles/styled-components';
import './TipsAndTricks.css';
import theme from 'styles/light-theme';

// ASSETS
import leftChevron from 'assets/svgs/left-chevron.svg';
import rightChevron from 'assets/svgs/right-chevron.svg';

// COMPONENTS
import UniversalTitle from 'components/UniversalTitle';

const TipSlide = styled.div`
  font-size: ${theme.fonts.sizes.primary};

  display: flex;
  flex-direction: column;

  padding: 20px 75px;

  .italicize {
    font-style: italic;
  }

  h1 {
    font-size: ${theme.fonts.sizes.xlarge};
    font-weight: ${theme.fonts.weights.primary};
  }

  @media (max-width: 400px) {
    padding: 0px 25px;
  }
`;

const SlideArrowButtonPrevious = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  z-index: 2;

  width: 24px;

  border: none;
  background-color: white;

  /* transition: all .25s ease-in; */

  cursor: pointer;

  :hover {
    /* background-color: rgba(0, 0, 0, 0.2); */
  }
`;

const SlideArrowButtonNext = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 2;

  width: 24px;

  border: none;
  background-color: white;

  /* transition: all .25s ease-in; */

  cursor: pointer;

  :hover {
    /* background-color: rgba(0, 0, 0, 0.2); */
  }
`;

const tip1 = (
  <TipSlide>
    <UniversalTitle
      title="Getting a job in renewables"
      primary={theme.textColors.black}
    />
    <p>
      The renewable energy industry is powered by people seeking to improve themselves and the planet, but making a difference and making a profit can be challenging. It&apos;s critical that you demonstrate your ability to face challenges and prove that you can perform in this fast growing environment.
    </p>
  </TipSlide>
);

const tip2 = (
  <TipSlide>
    <UniversalTitle
      title="Finding the right partner to work with"
      primary={theme.textColors.black}
    />
    <p>
      Organizations vary drastically by product offering, resources, company culture, compensation structure, and maturity. Consider the following while weighing your options:
    </p>
    <ul>
      <li>
        Want to build things from the ground up or do you want something more established?
      </li>
      <li>
        Interested in autonomy, structure, or a combination of the two?
      </li>
    </ul>
    <p>
      Some companies have a loose structure, while others are more ‘corporate’ in atmosphere. Finding the right fit requires knowledge about different organizations and where you can make the most meaningful contributions.
    </p>
    <p>
      NextIQ connects your qualifications to the <strong>best</strong> renewable companies in the industry.
    </p>
  </TipSlide>
);

const tip3 = (
  <TipSlide>
    <UniversalTitle
      title="Resume Tips"
      primary={theme.textColors.black}
    />
    <ul>
      <li>
        Create a brief summary of yourself at the top of the resume o r on a separate cover letter
        <ul>
          <li>
            What are some unique skills you’ve learned at NextIQ that can benefit your new role?
          </li>
          <li>
            Do you have extensive experience in different, but related fields?
          </li>
          <li>
            What are you passionate about? Apply this to the specific requirements of the job.
          </li>
        </ul>
      </li>
      <li>
        Use metrics whenever possible. Compare these metrics to others in the organization
      </li>
    </ul>
    <p>
      <span className={'italicize'}>
        Sample Resume Introduction:
      </span>
    </p>
    <p>
      Experienced installer in the high volume environment of roofing. Passionate about renewable energy, supporting my family, and growing clean energy within my community. I am seeking an organization that rewards performance and a positive attitude, where we can grow together.
    </p>
    <p>
      <span className={'italicize'}>
        Sample Metrics:
      </span>
    </p>
    <p>
      Sold 99 deals in 2020, ranked #2 of my 11 member team.
    </p>
    <p>
      Led teams of 10-14 individuals performing at 100%+ of quota for the past 2 years.
    </p>
  </TipSlide>
);

const TipsAndTricks: React.FC = () => {
  return (
    <>
      <Carousel 
        dynamicHeight={true} 
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(clickHandler, hasPrevious, label) => (
          hasPrevious &&
            <SlideArrowButtonPrevious aria-label="previous slide / item" onClick={clickHandler}><img src={leftChevron}></img></SlideArrowButtonPrevious>
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          hasNext &&
            <SlideArrowButtonNext aria-label="next slide / item" onClick={clickHandler}><img src={rightChevron}></img></SlideArrowButtonNext>
        )}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          isSelected ?
            (<li onClick={clickHandler} className="dot selected" value={index} role="button" tabIndex={index}></li>) :
            (<li onClick={clickHandler} className="dot unselected" value={index} role="button" tabIndex={index}></li>)
        )}
      >
        {tip1}
        {tip2}
        {tip3}
      </Carousel>
    </>
  );
};
export default TipsAndTricks;