import produce from 'immer';

import ActionTypes from './constants';
import {
  ContainerState, ContainerActions 
} from './types';

export const initialState: ContainerState = {
  loading: false,
  error: false,
  success: false,
  application: undefined,
};

function jobApplicationReducer(
  state: ContainerState = initialState,
  action: ContainerActions
): ContainerState {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SET_JOB_APPLICATION_DATA:
        draft.loading = false;
        draft.error = false;
        draft.application = action.payload;
        break;

      case ActionTypes.SUBMIT_JOB_APPLICATION:
        draft.loading = true;
        draft.error = false;
        break;

      case ActionTypes.SUBMIT_JOB_APPLICATION_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.application = action.payload;
        break;
      
      case ActionTypes.SUBMIT_JOB_APPLICATION_FAILURE:
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload;
        break;
      
      default:
        break;
    }
  });
}
export default jobApplicationReducer;