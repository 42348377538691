import styled from 'styles/styled-components';

interface FormGroupProps {
  horizontal?: boolean
  flex?: boolean
}

export default styled.div<FormGroupProps>`
  position: relative;
  display: flex;
  flex-direction: ${props => props.horizontal ? 'row' : 'column'};
  margin-bottom: ${props => props.theme.spacing.belowElement};
  ${props => props.flex ? 'flex: 1;' : ''}
`;