import React from 'react';

// STYLES
import styled from 'styles/styled-components';

import Convert from 'assets/images/convert70L.jpg';
import Dynamic from 'assets/images/DynamicSLR70.jpg';
import Pen from 'assets/images/PEN_70.jpg';

const EndorsementContainer = styled.div`
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(265px, 2fr));
  grid-template-rows: auto;
  height: 19vh;
  justify-items: center;
  align-items: center;

  @media (max-width: 590px) {
    height: 30vh;
    grid-template-rows: auto;
  }
`;

const Endorsement = styled.img`
  width: 200px;
`;

const Endorsements: React.FC = () => 
  (
    <EndorsementContainer>
      <Endorsement src={Dynamic}>

      </Endorsement>
      <Endorsement src={Convert}>

      </Endorsement>
      <Endorsement src={Pen}>

      </Endorsement>
    </EndorsementContainer>
  );

export default Endorsements;