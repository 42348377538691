import React, {
  useRef, useEffect 
} from 'react';
import Vimeo, { Options } from '@vimeo/player';

// STYLES
import styled from 'styles/styled-components';
import {
  max, breaks 
} from 'styles/breakpoints';

interface HeroProps {
  vimeoId: number
  placeholderImage?: string
  children?: any
}

const HeroContainer = styled.div<HeroProps>`
  width: ${props => props.theme.elements.hero.width};
  height: 380px; //470px; //${props => props.theme.elements.hero.height};
  background-image:
    linear-gradient(135deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.75) 100%), // FIX THIS
    url(${props => props.placeholderImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  ${max(breaks.tablet.max)} {
    height: 240px;
  }
`;
const Video = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding: 0;
  // padding-top: 100px;

  & > iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
  }
`;
const VideoHero: React.FC<HeroProps> = ({
  vimeoId,
  placeholderImage: _placeholder,
  children,
}: HeroProps) => {
  const vimeoNode = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const playerOptions: Options = {
      id: vimeoId,
      background: true,
    };
    new Vimeo(vimeoNode.current!, playerOptions);
    // const player = new Vimeo(vimeoNode.current, playerOptions)
  }, [
    vimeoId,
  ]);

  return (
    <HeroContainer vimeoId={vimeoId} placeholderImage={_placeholder}>
      <Video id={`hero-${vimeoId}`} ref={vimeoNode}>
        {children}
      </Video>
    </HeroContainer>
  );
};
export default VideoHero;