import React from 'react';
import styled from 'styles/styled-components';

interface Props {
  label: string
  htmlFor?: string
}

const Label = styled.label`
  font-size: ${props => props.theme.fonts.sizes.small};
  font-weight: ${props => props.theme.fonts.weights.primary};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: ${props => props.theme.spacing.belowTitle};
`;

const FieldLabel = (props: Props) => {
  const { label, htmlFor } = props;
  return <Label htmlFor={htmlFor}>{label}</Label>;
};

export default FieldLabel;