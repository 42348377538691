import { action } from 'typesafe-actions';
import ActionTypes from './constants';

export const openModal = (id: string, selectedJobId?: string, selectedJobCategory?: string) =>
  action(ActionTypes.OPEN_MODAL, {
    id,
    selectedJobId,
    selectedJobCategory,
  });

export const closeModal = () =>
  action(ActionTypes.CLOSE_MODAL);