import ActionTypes from './constants';
import {
  ContainerState, ContainerActions 
} from './types';

import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  selectedModal: '',
  selectedJobId: undefined,
  selectedJobCategory: undefined,
};

export default produce((
  draft: Draft<ContainerState>,
  action: ContainerActions
) => {
  switch (action.type) {

    case ActionTypes.OPEN_MODAL:
      return {
        selectedModal: action.payload.id,
        selectedJobId: action.payload.selectedJobId,
        selectedJobCategory: action.payload.selectedJobCategory,
      };

    case ActionTypes.CLOSE_MODAL:
      return {
        selectedModal: '',
      };

    default:
      return draft;
  }
}, initialState);