import React, {
  useRef, useEffect 
} from 'react';
import Vimeo, { Options } from '@vimeo/player';

// STYLES
import styled from 'styles/styled-components';

interface VimeoPlayerProps {
  vimeoId: number
  placeholderImage?: string
}

const Video = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  max-width: ${props => props.theme.video.width};
  width: calc(100vw - 2rem);
  max-height: ${props => props.theme.video.height};
  height: calc(((100vw - 4rem) / 16) * 9);
  
  &>iframe {
    width: 100%;
    height: 100%;
  }
`;
const VimeoPlayer: React.FC<VimeoPlayerProps> = ({
  vimeoId,
  placeholderImage: _placeholder,
}: VimeoPlayerProps) => {
  const vimeoNode = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const playerOptions: Options = {
      id: vimeoId,
    };
    new Vimeo(vimeoNode.current!, playerOptions);
  }, [
    vimeoId,
  ]);

  return (
    <Video id={`hero-${vimeoId}`} ref={vimeoNode} />
  );
};
export default VimeoPlayer;