// theme.ts

export interface IThemeInterface {
  colors: any
  textColors: any
  elementStyles: any
  fonts: any
  borders: any
  spacing: any
  radii: any
  lineSpacing: any
  page: any
  pageWidth: number
  header: any
  nav: any
  elements: any
  video: any
  footer: any
}

const colors = {
  white: '#FFF',
  black: '#000',
  primary: '#2170EA',
  lightBlue: '#1E9BEF',
  background: '#EFEFF4', // background
  gray: '#AEAEB3',      // nav
  orange: '#FFAF3A',
  green: '#56D500',
  red: '#ED3F1C',
  disabled: '#989898', // #CBCBCB
  lightGray: '#8F9091',
  darkGray: '#5F6162',
  hover: '#EFEFF46b',
  gold: '#EFC65E',
  silver: '#E3E7E8',
  bronze: '#ED9C5E',
};

const textColors = {
  primary: colors.black,
  secondary: '#8A8A8A',
  white: colors.white,
  black: colors.black,
  blue: colors.primary,
  error: colors.red,
  success: colors.green,
  link: colors.primary,
  accent: colors.orange,
  placeholder: colors.black,
  disabled: colors.disabled,
};

const elementStyles = {
  background: {
    default: colors.white,
    moz: colors.white, 
  },
  backdropFilter: {
    blur: 'blur(10px)',
  },
};

const fonts = {
  families: {
    primary: 'Gotham',
    secondary: 'sans-serif',
  },
  sizes: {
    small: '14px',
    primary: '16px',
    medium: '18px',
    large: '20px',
    xlarge: '26px',
    xxlarge: '32px',
  },
  weights: {
    light: '300',
    book: '400',        // need to set in global styles as font-family gotham size Book
    primary: '500',     // need to set in global styles as font-family gotham size Medium
    bold: '700',        // need to set in global styles as font-family gotham size Bold
  },
};

const lineSpacing = {
  small: '18px',
  primary: '22px',
  large: '31px',
  xlarge: '43px',
};

const radii = {
  small: '7px',
  primary: '9px',
  large: '15px',
  icon: '26px',
  achievments: '50%',
};

const borderWidth = {
  thick: 3,
  medium: 2,
  thin: 1,
};
const borders = {
  colors: {
    primary: colors.primary,
    secondary: colors.black,
    white: colors.white,
  },
  width: {
    primary: `${borderWidth.thick}px`,
    medium: `${borderWidth.medium}px`,
    thin: `${borderWidth.thin}px`,
  },
};

const between = 22;
const spacing = {
  between: `${between}px`,    // 1.5rem almost
  belowElement: '16px',       // 1rem
  belowTitle: '10px',
  belowFormInput: '36px',     // 24px, 42px
  pageContent: '30px',
  sectionTop: '100px',
  sectionBottom: '190px',
  subsectionTop: '60px',
  subsectionBottom: '100px',
  elementContent: '16px',
  modalContent: '42px',
  iconContent: '28px',
};

const pageWidth = 1200; // 1440
const page = {
  width: `${pageWidth}px`,
};

const header = {
  icon: {
    width: '42px',
    height: 'auto',
  },
  padding: spacing.pageContent,
  spacing: {
    width: '100vw',
    height: '200px',
  },
  spacingSmall: {
    width: '100vw',
    height: '100px',
  },
};

const navDimensions = {
  width: 940,
  height: 36,
  padding: 4,
};
const nav = {
  width: `${navDimensions.width}px`,
  height: `${navDimensions.height}px`,
  button: {
    // width is 940 minus the padding on the left and right (4 on either side)
    width: `${(navDimensions.width - (navDimensions.padding * 2))/5}px`,
    height: `${navDimensions.height}px`,
  },
};

const numberInRow = {
  two: 2,
  five: 5,
};
const elements = {
  button: {
    small: {
      width: '136px',
      height: '40px',
      // height: `calc(50px - (${borders.width.primary} * 2))`,
    },
    medium: {
      width: '186px',
      height: '40px',
      // height: `calc(50px - (${borders.width.primary} * 2))`,
    },
    large: {
      width: '226px',
      height: '40px',
      // height: `calc(50px - (${borders.width.primary} * 2))`,
    },
  },
  largeCard: {
    width: `${(pageWidth - between) / numberInRow.two}px`, // should be 709
    height: '425px', //'600px',
    titleHeight: '88px',
  },
  mediumCard: {
    width: `${(pageWidth - between) / numberInRow.two}px`, // should be 709
    // width: '680px',
    // height: '190px',
    height: '165px',
  },
  videoThumbnail: {
    width: `${(pageWidth - (between * (numberInRow.five - 1))) / numberInRow.five}px`, // '270px',
    height: '120px', //'140px,'
  },
  icon: {
    small: {
      size: '85px',
    },
    primary: {
      size: '100px',
    },
  },
  heroPreview: {
    width: '190px',
    height: '115px',
  },
  progress: {
    small: {
      height: '4px',
    },
    medium: {
      height: '6px',
    },
    large: {
      height: '8px',
    },
  },
  divider: {
    width: `${pageWidth}px`,
    height: `${borderWidth.thin}px`,
  },
  hero: {
    width: '100vw',
    height: '540px',
  },
  banner: {
    width: '100vw',
    height: '280px',
  },
  textInput: {
    height: '36px',
  },
  textareaInput: {
    height: '72px',
  },
  search: {
    small: {
      width: '256px',
      height: '36px',
    },
    large: {
      width: '620px',
      height: '50px',
    },
    opacity: '0.3',
  },
  portrait: {
    tiny: {
      size: '50px',
    },
    small: {
      size: '105px',
    },
    large: {
      size: '150px',
    },
  },
};

const video = {
  width: `${navDimensions.width}px`,
  height: `${(navDimensions.width / 16) * 9}px`,
};

const footer = {
  width: '100vw',
  height: '100px',
};

const theme: IThemeInterface = {
  colors,
  textColors,
  elementStyles,
  fonts,
  borders,
  spacing,
  radii,
  lineSpacing,
  page,
  pageWidth,
  header,
  nav,
  elements,
  video,
  footer,
};

export default theme;
export { colors, navDimensions };