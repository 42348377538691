import React from 'react';
import { useDispatch } from 'react-redux';

// STYLES
import styled from 'styles/styled-components';
import lightTheme from 'styles/light-theme';
import './JobCards.css';

// COMPONENTS
import Button from 'components/Button';
import { Job } from 'API';
import { Size } from 'constants/Size';
import { openModal } from 'shared/modal/actions';
import { ApplicationModalId } from 'shared/modal/constants';
import UniversalTitle from 'components/UniversalTitle';

// IMAGES
import SaleImage from 'assets/images/sale.jpg';
import InstallerImage from 'assets/images/install.jpg';
import ManagementImage from 'assets/images/management.jpg';
import OtherImage from 'assets/images/other.jpg';

// UTILS
import { Align } from 'constants/Align';

const JobCardContainer = styled.div`
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 1rem;

  @media (max-width: 375px) {
    grid-template: 1fr / repeat(auto-fit, minmax(256px, 1fr)); // 256px is the min width for the iphone 5
  }
`;

const Card = styled.div`
  background-color: ${lightTheme.textColors.white};
  border-radius: 25px;
  padding: 0px 10px;
  height: 425px;

  position: relative;

  #cardImage {
    position: absolute;
    left: 0;
    right: 0;
    height: 190px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;

    .jobCategoryTitle {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 45px;
    }
  }

  .btnApply {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: auto;
  }

  h1 {
    font-size: ${lightTheme.fonts.sizes.xlarge};
    font-weight: ${lightTheme.fonts.weights.primary};
    position: absolute;
    top: 100px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const CardDescription = styled.p`
  position: absolute;
  top: 200px;
  margin: 5px 15px;
`;

interface JobCardProps {
  jobs: Job[];
}

type Props = JobCardProps

const JobCards: React.FC<Props> = ({ jobs }: Props) => {

  const imageSequence = {
    0: SaleImage,
    1: InstallerImage,
    2: ManagementImage,
    3: OtherImage,
  };

  const dispatch = useDispatch();

  const openApplicationModal = (selectedJobId: string, selectedJobCategory: string): void => {
    dispatch(openModal(ApplicationModalId, selectedJobId, selectedJobCategory));
  };

  return (
    <JobCardContainer>
      {[ 
        ...jobs, 
      ].sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((job, index) => (
        <Card key={index}>
          <div id="cardImage">
            <img style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }} src={imageSequence[job.sequenceNumber]} />
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'black',
              opacity: 0.6,
            }}></div>
            <UniversalTitle
              title={job.job_category}
              align={Align.CENTER}
              primary={lightTheme.textColors.white}
              size={Size.XLARGE}
              textAlign={Align.CENTER}
              className={'jobCategoryTitle'}
            />
          </div>
          <CardDescription>{job.description}</CardDescription>
          <Button
            secondary
            size={Size.SMALL}
            onClick={() => openApplicationModal(job.id, job.job_category)}
            className="btnApply"
          >
            Apply
          </Button>
        </Card>
      ))}
    </JobCardContainer>
  );
};
export default JobCards;