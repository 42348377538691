import React, { useState } from 'react';
import styled from 'styles/styled-components';

interface Props {
  src: string | Record<string, unknown>
  alt?: string
  width?: string
  height?: string
  className?: string
  onClick?: () => void
}

interface ClickableProp {
  clickable: boolean
}

const Image = styled.img<ClickableProp>`
  ${props => props.clickable ? 'cursor: pointer' : ''}
`;

const Img: React.FC<Props> = ({
  src,
  alt,
  width,
  height,
  className,
  onClick,
}: Props) => {
  const [
    clickable,
  ] = useState<boolean>(!!onClick);
  const handleOnClick = (): void => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Image
      className={className}
      src={src as string}
      alt={alt}
      width={width}
      height={height}
      onClick={handleOnClick}
      clickable={clickable}
    />
  );
};
export default Img;