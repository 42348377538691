import {
  ContainerState, ContainerActions 
} from './types';
import ActionTypes from './constants';

// CONSTANTS
import { LoadingState } from 'constants/LoadingState';

export const initialState: ContainerState = {
  loading: true,
  error: false,
  contactUsLoadingState: LoadingState.IDLE,
};

function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions
): ContainerState {
  switch (action.type) {

    case ActionTypes.SET_APP_ERROR:
      console.log(action.payload);
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.SET_GLOBAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ActionTypes.CONTACT_US:
      return {
        ...state,
        contactUsLoadingState: LoadingState.CREATE,
      };

    case ActionTypes.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsLoadingState: LoadingState.IDLE,
      };

    case ActionTypes.CONTACT_US_FAILURE:
      return {
        ...state,
        error: action.payload,
        contactUsLoadingState: LoadingState.IDLE,
      };

    default:
      return state;
  }
}

export default appReducer;
