import * as styledComponents from 'styled-components';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents;

export {
  css, createGlobalStyle, keyframes, ThemeProvider 
};
export default styled;
