import React from 'react';
import styled, { keyframes } from 'styles/styled-components';

import { Size } from 'constants/Size';

interface CircularLoadingProps {
  size?: Size
  complete?: boolean
}

const loader = {
  small: {
    size: 20,
  },
  medium: {
    size: 30,
  },
  large: {
    size: 50,
  },
  xlarge: {
    size: 80,
  },
  width: 2,
};

const check = {
  small: {
    top: 9,
    left: 3,
  },
  medium: {
    top: 9,
    left: 3,
  },
  large: {
    top: 9,
    left: 3,
  },
};

const loaderSpin = props => {
  return keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
};

const checkmark = props => {
  let width, height;
  switch (props.size) {
    case Size.SMALL:
      width = loader.small.size * 0.25;
      height = loader.small.size * 0.5;
      break;
    case Size.MEDIUM:
      width = loader.medium.size * 0.25;
      height = loader.medium.size * 0.5;
      break;
    case Size.LARGE:
    default:
      width = loader.large.size * 0.25;
      height = loader.large.size * 0.5;
      break;
  }
  return keyframes`
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: ${width}px;
      opacity: 1;
    }
    40% {
      height: ${height}px;
      width: ${width}px;
      opacity: 1;
    }
    100% {
      height: ${height}px;
      width: ${width}px;
      opacity: 1;
    }
  `;
};

const Loader = styled.div<CircularLoadingProps>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // signup component
  align-self: center;
  width: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return loader.small.size;
      case Size.MEDIUM:
        return loader.medium.size;
      case Size.LARGE:
      default:
        return loader.large.size;
    }
  }}px;
  height: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return loader.small.size;
      case Size.MEDIUM:
        return loader.medium.size;
      case Size.LARGE:
      default:
        return loader.large.size;
    }
  }}px;
  border: ${loader.width}px solid rgba(0,0,0,0.5); // rgba(0, 0, 0, 0.2);
  border-left-color: ${props => props.theme.colors.green};
  border-radius: 50%;

  -webkit-animation: ${() => loaderSpin} 800ms infinite linear;
  animation: ${() => loaderSpin} 800ms infinite linear;

  &.complete {
    -webkit-animation: none;
    animation: none;
    border-color: ${props => props.theme.colors.green};
    transition: border 500ms ease-out;
  }
`;
const Checkmark = styled.div<CircularLoadingProps>`
  content: '';
  position: absolute;
  top: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return check.small.top;
      case Size.MEDIUM:
        return check.medium.top;
      case Size.LARGE:
      default:
        return check.large.top;
    }
  }}px;
  left: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return check.small.left;
      case Size.MEDIUM:
        return check.medium.left;
      case Size.LARGE:
      default:
        return check.large.left;
    }
  }}px;
  opacity: 1;
  height: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return loader.small.size * 0.5;
      case Size.MEDIUM:
        return loader.medium.size * 0.5;
      case Size.LARGE:
      default:
        return loader.large.size * 0.5;
    }
  }}px;
  width: ${props => {
    switch (props.size) {
      case Size.SMALL:
        return loader.small.size * 0.25;
      case Size.MEDIUM:
        return loader.medium.size * 0.25;
      case Size.LARGE:
      default:
        return loader.large.size * 0.25;
    }
  }}px;
  transform-origin: left top;
  border-right: ${loader.width}px solid ${props => props.theme.colors.green};
  border-top: ${loader.width}px solid ${props => props.theme.colors.green};

  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: ${props => checkmark};
  transform: scaleX(-1) rotate(135deg);
`;

const CircularLoading: React.FC<CircularLoadingProps> = (props: CircularLoadingProps) => {
  return (
    <Loader {...props} className={props.complete ? 'complete' : ''}> 
      { props.complete && <Checkmark {...props} /> }
    </Loader>
  );
};
export default CircularLoading;
