import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';

const selectModal = (state: ApplicationRootState) => {
  return state.modal;
};

const makeSelectSelectedModal = () =>
  createSelector(selectModal, substate => substate.selectedModal);

const makeSelectSelectedJobId = () =>
  createSelector(selectModal, substate => substate.selectedJobId);

const makeSelectSelectedJobCategory = () =>
  createSelector(selectModal, substate => substate.selectedJobCategory);

export {
  selectModal,
  makeSelectSelectedModal,
  makeSelectSelectedJobId,
  makeSelectSelectedJobCategory
};