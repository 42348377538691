export enum Position {
  TOPLEFT,
  TOP,
  TOPRIGHT,
  LEFTMIDDLE,
  RIGHTMIDDLE,
  CENTER,
  BOTTOMLEFT,
  BOTTOM,
  BOTTOMRIGHT,
  LEFT,
  RIGHT,
}